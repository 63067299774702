import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { actionsAddTiktok } from "../redux/AddTiktokRedux";
import { useState } from "react";
import _ from "lodash";

const CookieTokenTab = () => {
  const [cookieToken, setCookieToken] = useState('');

  const dispatch = useDispatch();
  return (
    <>
      <div style={{ marginBottom: '7px', color: '#344767' }}>Cookie & Token</div>
      <TextArea
        rows={4}
        placeholder="Vui lòng nhập Token hoặc Cookie Tiktok vào đây rồi Thêm Tài Khoản"
        onChange={(e) => setCookieToken(e.target.value)}
      />
      <div style={{ marginTop: '8px', color: '#8897AF' }}>
        <ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
          <li>Xem video hướng dẫn lấy Cookie: <strong><a style={{ color: 'red' }}>Tại đây</a></strong></li>
          <li>Không được bấm đăng xuất trên trình duyệt lấy Token hoặc Cookie, tài khoản sẽ hết quyền truy cập</li>
          <li>Tài khoản hết hạn báo DIE vui lòng Thêm lại tài khoản để Update lại</li>
        </ul>
        <br />
        <Button
          type="primary"
          style={{ width: '100%', marginTop: '10px' }}
          size="large"
          onClick={() => {
            dispatch(actionsAddTiktok.addTiktok({
              type: 'cookies',
              userphone: '0123456789',
              api_key: 'LBMZS6BZDK8O3CQ7FPA4XYFUUIIR25L93TGOW2DNV51W1EAHEJTHPKGQ9R8467CNYXSV',
              cookies: cookieToken,
              verify: 'winxu.com.vn'
            }));
            setCookieToken('');
          }}
          disabled={!_.trim(cookieToken)}
        >
          THÊM TÀI KHOẢN TIKTOK
        </Button>
      </div>
    </>
  );
}

export default CookieTokenTab;
