import _ from "lodash";
import { UserWinXuModel } from "../main/models/UserWinXuModel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { Button, Col, Image, Input, Radio, Row, Select, Space, Tag } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { actionsAddTiktok } from "../addtiktok";
import { AccountTiktokModel } from "../addtiktok/models/AccountTiktokModel";
import { actionsSendGift } from "./redux/SendGiftRedux";
import GetLiveResponse from "./models/GetLiveResponse";
import GetGiftsResponse from "./models/GetGiftsResponse";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { SendGiftPayload } from "./models/SendGiftPayload";
import { GetUserResponse } from "./models/GetUserResponse";

const validationSchema = Yup.object().shape({
  numSend: Yup.number().min(1, 'Số lượng cần tặng tối thiểu là 1'),
  resend: Yup.number().min(1, 'Gửi lặp lại tối thiểu là 1'),
});

const SendGift = () => {
  const listAccountTiktok =
    useSelector<RootState>(({ addTiktok }) => addTiktok.listAccountTiktok, shallowEqual) as AccountTiktokModel[];
  const userWinXu: UserWinXuModel =
    useSelector<RootState>(({ auth }) => auth.userWinXu, shallowEqual) as UserWinXuModel;
  const liveResponse: GetLiveResponse =
    useSelector<RootState>(({ sendgift }) => sendgift.liveResponse, shallowEqual) as GetLiveResponse;
  const giftsReponse: GetGiftsResponse[] =
    useSelector<RootState>(({ sendgift }) => sendgift.giftsResponse, shallowEqual) as GetGiftsResponse[];
  const userResponse: GetUserResponse =
    useSelector<RootState>(({ sendgift }) => sendgift.userResponse, shallowEqual) as GetUserResponse;

  const [accounts, setAccounts] = useState<string | string[]>();
  const [usernameLive, setUsernameLive] = useState('');
  const [usernameGetGift, setUsernameGetGift] = useState('');
  const [usernameGetGiftExecuted, setUsernameGetGiftExecuted] = useState('');
  const [onWebPhone, setOnWebPhone] = useState('on_phone');
  const [giftSelected, setGiftSelected] = useState<string | undefined>(undefined);

  const formik = useFormik({
    initialValues: {
      numSend: 1,
      resend: 1,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: () => { console.log('onSubmit') }
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsAddTiktok.getTiktokAccounts());
    dispatch(actionsSendGift.getGifts());
  }, [dispatch]);

  useEffect(() => {
    if (userResponse?.uid) {
      setUsernameGetGift(userResponse.uid as string);
      setUsernameGetGiftExecuted(userResponse.uid as string);
    }
  }, [userResponse?.uid]);

  return (
    <>
      <div style={{ marginTop: '20px', marginBottom: '60px', color: 'white', fontSize: '20px' }}>Main Page</div>
      <div className='row g-5 gx-xxl-8 spinner spinner-primary mr-15'>
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-xxl-5`}>
            <div className="card-body py-3 fw-bold fs-6" style={{ height: '120px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ width: '100px', height: '100px', padding: '10px', border: '1px solid #ffffff', borderRadius: '10px', boxShadow: '0px 0px 24px 0px #C5C5C5' }}>
              <Image style={{ maxHeight: '90px' }} src={userWinXu?.image ? `data:image/png;base64,${userWinXu?.image}` : toAbsoluteUrl('/media/icons/duotune/xu/avatar.png')} preview={false} />
              </div>
              <div style={{ marginLeft: '10px', color: 'grey', alignItems: 'center' }}>
                <div>{_.get(userWinXu, 'name', '')}</div>
                <div>Thành viên</div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
              <div>{_.get(userWinXu, 'balance', '')}</div>
                <br />
                <span>Số dư</span>
              </div>
            </div>
          </div>
        </div>
        <div className='row g-5 gx-xxl-12'>
          <div className='col-xxl-8' style={{ color: '#344767' }}>
            <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', marginBottom: '20px' }}>Tặng Quà TikTok</span>
              </div>
              <Space direction="vertical">
                <span>Chọn tài khoản <InfoCircleOutlined /></span>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  size="large"
                  onChange={(value: string | string[]) => setAccounts(value)}
                  value={accounts}
                  options={(listAccountTiktok || []).map(ac => ({
                    value: ac.uid,
                    label: `${ac.name} (${ac.country}) (${ac.balance})`
                  }))}
                />
              </Space>
              <br />
              <Space direction="vertical">
                <span>Username Live</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  value={usernameLive}
                  onChange={(e) => setUsernameLive(e.target.value)}
                />
              </Space>
              <br />
              <Button
                type="primary"
                size="large"
                disabled={!_.trim(usernameLive)}
                onClick={() => dispatch(actionsSendGift.getLive(usernameLive))}
              >
                LẤY THÔNG TIN ROOM LIVE
              </Button>
              <br />
              <Row>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Tên Tiktok</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'nickname', 'Tên tiktok Idol sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Theo Dõi</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'follow', 'Số theo dõi Idol sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Room Live</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'idlive', 'ID ROOM Live sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
              </Row>
              <br />
              <Radio.Group onChange={(e) => setOnWebPhone(e.target.value)} value={onWebPhone}>
                <Space direction="vertical">
                  <Radio value='on_web'>Tặng Quà trên Web <Tag color="blue">2,000 Đ</Tag></Radio>
                  <Radio value='on_phone'>Tặng Quà trên Phone  <Tag color="blue">2,000 Đ</Tag></Radio>
                </Space>
              </Radio.Group>
              <br />
              <Space direction="vertical">
                <span>Username Nhận Quà</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  // defaultValue='hufushop1417'
                  onChange={(e) => setUsernameGetGift(e.target.value)}
                  value={usernameGetGift}
                  onBlur={(e) => {
                    const value = e.target.value;
                    if (_.trim(value) && value !== usernameGetGiftExecuted) {
                      dispatch(actionsSendGift.getUser(e.target.value));
                      setUsernameGetGiftExecuted(value);
                    }
                  }}
                />
                {userResponse?.uid ? <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                  <div style={{ width: '40px', height: '40px' }}>
                    <Image src={userResponse.avatar} preview={false} />
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <span>{userResponse.nickname}</span>
                    <br />
                    <span style={{ color: '#8E9CB3', fontSize: '12px' }}>@{userResponse.uniqueId}</span>
                  </div>
                </div> : null }
              </Space>
              <br />
              <Space direction="vertical">
                <div><span>Chọn Quà </span>{_.isEmpty(giftSelected) ? <span role='alert' style={{ color: 'red' }}>(Vui lòng chọn quà)</span> : null}</div>
                <Row>
                  {(giftsReponse || []).map(g => (
                    <div
                      key={g.id}
                      style={{
                        textAlign: 'center',
                        marginRight: '25px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setGiftSelected(g.id)}
                    >
                      <Image src={g.icon} style={{ width: '60px', border: `${giftSelected === g.id ? '2px solid red' : 'none'}` }} preview={false} />
                      <br />
                      <span>{g.diamond} xu</span>
                    </div>
                  ))}
                </Row>
              </Space>
              <br />
              <Space direction="vertical">
                <span>Số lượng cần tặng</span>
                <Input
                  type="number"
                  min={1}
                  defaultValue={1}
                  size="large"
                  {...formik.getFieldProps('numSend')}
                />
                {(formik.touched.numSend && formik.errors.numSend) ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numSend}</span>
                    </div>
                  </div>
                ) : null}
              </Space>
              <br />
              <Space direction="vertical">
                <span>Gửi lặp lại</span>
                <Input
                  type="number"
                  min={1}
                  max={10}
                  defaultValue={1}
                  size="large"
                  {...formik.getFieldProps('resend')}
                />
                {(formik.touched.resend && formik.errors.resend) ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.resend}</span>
                    </div>
                  </div>
                ) : null}
              </Space>
              <br />
              <div style={{ width: '100%', height: '60px', border: '1px solid #F8F9FA', borderRadius: '10px', backgroundColor: '#F8F9FA', textAlign: 'center' }}>
                <div style={{ marginTop: '12px', fontSize: '30px' }}>Tổng : <span style={{ fontSize: '30px', color: '#2192FE' }}>{(2000).toLocaleString("en-US")}</span></div>
              </div>
              <br />
              <Button
                type="primary"
                size="large"
                disabled={!liveResponse || !_.trim(usernameGetGift) || _.isEmpty(accounts) || !_.isEmpty(formik.errors) || !giftSelected}
                onClick={async () => {
                  await formik.submitForm();
                  if (_.isEmpty(formik.errors)) {
                    const sendGiftPayload: SendGiftPayload = {
                      user: (_.isArray(accounts) ? accounts.join(',') : accounts) as string,
                      roomId: liveResponse.idlive as string,
                      toUser: usernameGetGift,
                      giftId: giftSelected as string,
                      amount: formik.values.numSend,
                    };
                    dispatch(actionsSendGift.sendGift(sendGiftPayload));
                  }
              }}>
                TẶNG QUÀ TIKTOK
              </Button>
            </div>
          </div>
          <div className='col-xxl-4' style={{ color: '#344767' }}>
            <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendGift;