import axios from 'axios';
import _ from 'lodash';
import AddTiktokPayload from '../models/AddTiktokPayload';

const WIN_XU_API_URL = process.env.REACT_APP_WIN_XU_API_URL;

const POST_ADD_TIKTOK = `${WIN_XU_API_URL}/api/add-account`;
const GET_TIKTOK_ACCOUNTS = `${WIN_XU_API_URL}/api/get-accounts`;
const DELETE_TIKTOK_ACCOUNT = `${WIN_XU_API_URL}/api/remove-account`;
const REFRESH_TIKTOK_ACCOUNT = `${WIN_XU_API_URL}/api/update-account`;

export const apiAddTiktok = (payload: AddTiktokPayload) => {
  return axios.post(POST_ADD_TIKTOK, payload);
}

export const apiGetTiktokAccounts = () => {
  return axios.get(GET_TIKTOK_ACCOUNTS);
}

export const apiDeleteTiktokAccount = (uid: string) => {
  return axios.get(DELETE_TIKTOK_ACCOUNT, { params: { uid } });
}

export const apiRefreshTiktokAccount = (uid: string) => {
  return axios.get(REFRESH_TIKTOK_ACCOUNT, { params: { uid } });
}
