import { styled } from "@material-ui/core";
import { BounceLoader, BeatLoader, PacmanLoader } from "halogenium";
import { FC } from "react";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
  isLoading: boolean,
  type?: string,
  percentage?: number,
}

export const Loading: FC<Props> = ({isLoading, type, percentage}) => {
  const className = isLoading ? 'loading' : '';
  const overlay = isLoading ? 'overlay' : '';
  const getLoader = () => {
    switch (type) {
      case "getAll": {
        return <BounceLoader color="#45A1FF" />;
      }
      case "getPercentage": {
        return <>
          <CircularProgressbar
            // @ts-ignore
            value={percentage}
            text={`${percentage}%`}
          />
          <div style={{textAlign: 'center'}}>
            <BeatLoader color="#45A1FF" />
          </div>
        </>;
      }
      default:
        return <PacmanLoader color="#45A1FF" />;
    }
  }
  return <div className={`${overlay}`}>
    <div className={className} style={type === 'getPercentage' ? {width: '6%'} : {}}>
      {isLoading ? getLoader() : null}
    </div>
  </div>;
}