import axios from 'axios'
import _ from 'lodash'
import { SendGiftPayload } from '../models/SendGiftPayload';

const WIN_XU_API_URL = process.env.REACT_APP_WIN_XU_API_URL;

const GET_GIFTS = `${WIN_XU_API_URL}/api/gifts`;
const GET_GET_LIVE = `${WIN_XU_API_URL}/api/get-live`;
const POST_SEND_GIFT = `${WIN_XU_API_URL}/api/send-gift`;
const GET_USER = `${WIN_XU_API_URL}/api/get-user`;

export function apiGetGifts() {
  return axios.get(GET_GIFTS);
}

export function apiGetLive(user: string) {
  return axios.get(GET_GET_LIVE, { params: { user } });
}

export function apiSendGift(sendGiftPayload: SendGiftPayload) {
  return axios.post(POST_SEND_GIFT, sendGiftPayload);
}

export function apiGetUser(username: string) {
  return axios.get(GET_USER, { params: { user: username } });
}
