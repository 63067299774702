import { Anchor, Button, Image, Input, Space, Tag, Upload, UploadProps } from "antd";
import Header from "../../Header";
import { UserOutlined, LockOutlined, ClockCircleOutlined, DesktopOutlined, EditOutlined } from '@ant-design/icons';
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { UserWinXuModel } from "../../../main/models/UserWinXuModel";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../setup";
import moment from "moment";
import { useMemo, useState } from "react";
import _ from "lodash";
import { RcFile } from "antd/lib/upload";
import { updatePassword } from "../../../../firebase";
import { actionsProfile } from "../../redux/ProfileRedux";

const { Link } = Anchor;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  }
);

const AccountInformation = () => {
  const userWinXu: UserWinXuModel =
    useSelector<RootState>(({ auth }) => auth.userWinXu, shallowEqual) as UserWinXuModel;
  const updatePasswordState: string =
    useSelector<RootState>(({ profile }) => profile.updatePasswordState, shallowEqual) as string;

  const [imageProfile, setImageProfile] = useState(userWinXu?.image);
  const [name, setName] = useState(userWinXu.name);
  const [phone, setPhone] = useState(userWinXu?.phone);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [bankAccountBankName, setBankAccountBankName] = useState(userWinXu.bankAccount?.bankName);
  // const [bankAccountNumber, setBankAccountNumber] = useState(userWinXu.bankAccount?.bankNumber);
  // const [bankAccountName, setBankAccountName] = useState(userWinXu.bankAccount?.bankAccountName);

  const userAccountChanged = useMemo(() => {
    const nameInput = _.trim(name);
    const phoneInput = _.trim(phone);
    const isChanged = (!!imageProfile && !_.isEqual(imageProfile, userWinXu?.image)) ||
      (!!nameInput && !_.isEqual(nameInput, userWinXu.name)) ||
      (!!phoneInput && !_.isEqual(phoneInput, userWinXu.phone));
    if (!isChanged) {
      return {
        isChanged
      };
    }
    let errors = {};
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    if (!regexPhoneNumber.test(phoneInput)) {
      errors = { ...errors, phone: 'Vui lòng nhập đúng số điện thoại' };
      return {
        isChanged,
        errors
      }
    }
    return {
      isChanged,
      errors
    }
  }, [name, phone, userWinXu, imageProfile]);

  const passwordChangedInfo = useMemo(() => {
    const isChanged = oldPassword && newPassword && confirmPassword;
    if (!isChanged) {
      return {
        isChanged
      }
    }
    let errors = {};
    const regex = /^(?=.*[0-9])(?=.*[A-Za-z])(?=.*\W)(?!.* ).{8,}$/;
    if (newPassword && !regex.test(newPassword)) {
      if (!_.isEqual(newPassword, confirmPassword)) {
        errors = { ...errors, newPassword: 'Mật khẩu phải có tối thiểu 6 kí tự, ít nhất 1 số và 1 kí tự đặc biệt' }
        return {
          isChanged,
          errors
        }
      }
    }
    if (!_.isEqual(newPassword, confirmPassword)) {
      errors = { ...errors, confirmPassword: 'Mật khẩu mới không trùng khớp' };
      return {
        isChanged,
        errors
      }
    }
    return {
      isChanged,
      errors
    }
  }, [oldPassword, newPassword, confirmPassword]);

  // const isBankAccountChanged: boolean = useMemo(() => {
  //   const bankAccountNumberInput = _.trim(bankAccountNumber);
  //   return !!bankAccountNumberInput && !!bankAccountBankName && !!bankAccountName &&
  //     !_.isEqual(bankAccountBankName, userWinXu.bankAccount?.bankName) &&
  //     !_.isEqual(bankAccountNumberInput, userWinXu.bankAccount?.bankNumber) &&
  //     !_.isEqual(bankAccountName, userWinXu.bankAccount?.bankAccountName);
  // }, [bankAccountBankName, bankAccountName, bankAccountNumber, userWinXu.bankAccount]);

  const props: UploadProps = {
    beforeUpload: file => {
      return false;
    },
    onChange: async (info) => {
      const { file } = info;
      const fileData = (await getBase64(file as RcFile)).replace(/^data:image\/[a-z]+;base64,/, "");
      setImageProfile(fileData);
    },
  };

  const dispatch = useDispatch();

  return (
    <>
      <Header />
      <div className='row g-5 gx-xxl-12'>
        <div className='col-xxl-3' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <Anchor affix={false}>
              <Link href="#account_information" title={<div style={{ marginBottom: '10px' }}><UserOutlined /><span style={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '17px' }}>Thông Tin Tài Khoản</span></div>} />
              {/* <Link href="#bank_account" title={<div style={{ marginBottom: '10px' }}><BankOutlined /><span style={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '17px' }}>Thêm Ngân Hàng</span></div>} /> */}
              <Link href="#change_password" title={<div style={{ marginBottom: '10px' }}><LockOutlined /><span style={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '17px' }}>Đổi Mật Khẩu</span></div>} />
              {/* <Link href="#2fa" title={<div style={{ marginBottom: '10px' }}><SecurityScanOutlined /><span style={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '17px' }}>Xác Minh 2 Bước</span></div>} /> */}
              <Link href="#login_session" title={<div style={{ marginBottom: '10px' }}><ClockCircleOutlined /><span style={{ fontWeight: 'bold', marginLeft: '10px', fontSize: '17px' }}>Phiên Đăng Nhập</span></div>} />
            </Anchor>
          </div>
        </div>
        <div className='col-xxl-9' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <div id="account_information">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', marginBottom: '20px' }}>Thông Tin Tài Khoản</span>
              </div>
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Ảnh đại diện</span>
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    padding: '10px',
                    border: '1px solid #ffffff',
                    borderRadius: '10px',
                    boxShadow: '0px 0px 24px 0px #C5C5C5',
                  }}>
                  <Image
                    style={{ maxHeight: '140px' }}
                    src={imageProfile ? `data:image/png;base64,${imageProfile}` : toAbsoluteUrl('/media/icons/duotune/xu/avatar.png')}
                    preview={false}
                  />
                  <Upload
                    showUploadList={false}
                    accept='image/*'
                    {...props}
                    className="profile-image"
                  >
                    <EditOutlined style={{ fontSize: '20px', position: 'absolute', left: '135px', cursor: 'pointer' }} />
                  </Upload>
                </div>
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Họ và tên</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  defaultValue={userWinXu.name}
                  value={name}
                  onChange={(e) => setName(_.trim(e.target.value))}
                />
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Số điện thoại</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  disabled={!!userWinXu.phone}
                  value={phone}
                  onChange={(e) => setPhone(_.trim(e.target.value))}
                />
                {_.get(userAccountChanged, 'errors.phone') ? <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{_.get(userAccountChanged, 'errors.phone')}</span>
                  </div>
                </div> : null}
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Ngày tham gia</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  disabled
                  value={moment(new Date(userWinXu.createdTime)).format('DD-MM-YYYY HH:mm:ss')}
                />
              </Space>
              <br />
              <br />
              <ul>
                <li>Muốn đổi Avatar riêng vui lòng tải ảnh của bạn lên</li>
              </ul>
              <Button
                type="primary"
                style={{ width: '100%' }}
                disabled={!userAccountChanged.isChanged}
                onClick={() => {
                  let profileUpdated = {};
                  if (imageProfile && !_.isEqual(imageProfile, userWinXu?.image)) {
                    profileUpdated = { ...profileUpdated, imageProfile };
                  }
                  if (name && !_.isEqual(name, userWinXu.name)) {
                    profileUpdated = { ...profileUpdated, name };
                  }
                  if (phone && !_.isEqual(phone, userWinXu.phone)) {
                    profileUpdated = { ...profileUpdated, phone };
                  }
                  dispatch(actionsProfile.updateProfile(profileUpdated));
                }}
              >
                CHỈNH SỬA THÔNG TIN
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="bank_account" className='row g-5 gx-xxl-12'>
        <div className='col-xxl-3' style={{ color: '#344767' }} />
        <div className='col-xxl-9' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', marginBottom: '20px' }}>Tài Khoản Ngân Hàng</span>
              </div>
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Thêm ngân hàng</span>
                <Select
                  options={Constant.banks}
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  placeholder='Chọn Ngân Hàng'
                  value={bankAccountBankName}
                />
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Số tài khoản</span>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Nhập Số Tài Khoản Ngân Hàng"
                  size="large"
                  defaultValue={bankAccountNumber}
                />
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Chủ tài khoản</span>
                <Input
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Chủ Tài Khoản"
                  disabled
                  defaultValue={bankAccountName}
                />
              </Space>
              <br />
              <br />
              <ul>
                <li>Mỗi tài khoản được phép thêm 5 tài khoản Ngân Hàng</li>
              </ul>
              <Button type="primary" style={{ width: '100%' }} disabled={!isBankAccountChanged}>THÊM NGÂN HÀNG</Button>
            </div>
          </div>
        </div>
      </div> */}
      {userWinXu.accountType === 'emailAccount' ? <div id="change_password" className='row g-5 gx-xxl-12'>
        <div className='col-xxl-3' style={{ color: '#344767' }} />
        <div className='col-xxl-9' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', marginBottom: '20px' }}>Đổi Mật Khẩu</span>
              </div>
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Mật khẩu cũ</span>
                <Input.Password
                  style={{ width: '100%' }}
                  placeholder="Nhập Mật Khẩu Cũ"
                  size="large"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                {updatePasswordState === 'error' ? <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>Password không đúng</span>
                  </div>
                </div> : null}
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Mật khẩu mới</span>
                <Input.Password
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Nhập Mật Khẩu Mới"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {_.get(passwordChangedInfo, 'errors.newPassword') ? <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{_.get(passwordChangedInfo, 'errors.newPassword')}</span>
                  </div>
                </div> : null}
              </Space>
              <br />
              <br />
              <Space direction="vertical" style={{ width: '100%' }}>
                <span>Nhập lại mật khẩu mới</span>
                <Input.Password
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Nhập Lại Mật Khẩu Mới"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {_.get(passwordChangedInfo, 'errors.confirmPassword') ? <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{_.get(passwordChangedInfo, 'errors.confirmPassword')}</span>
                  </div>
                </div> : null}
              </Space>
              <br />
              <br />
              <br />
              <br />
              <span style={{ fontSize: '20px', marginBottom: '20px' }}>Yêu cầu về mật khẩu</span>
              <br />
              <br />
              <span style={{ fontSize: '17px' }}>Để có mật khẩu mạnh:</span>
              <br />
              <ul>
                <li>Tối thiểu 6 ký tự</li>
                <li>Có ít nhật 1 số</li>
                <li>Có ít nhất 1 ký tự đặc biệt</li>
              </ul>
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={async () => {
                  dispatch(actionsProfile.setUpdatePasswordLoading(true));
                  const isSuccess = await updatePassword(userWinXu.email, oldPassword, newPassword);
                  if (isSuccess) {
                    dispatch(actionsProfile.setUpdatePasswordState('success'));
                    setOldPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                  } else {
                    dispatch(actionsProfile.setUpdatePasswordState('error'));
                  }
                  dispatch(actionsProfile.setUpdatePasswordLoading(false));
                }}
                disabled={!passwordChangedInfo.isChanged || !_.isEmpty(passwordChangedInfo.errors)}
              >
                ĐỔI MẬT KHẨU
              </Button>
            </div>
          </div>
        </div>
      </div> : null}
      {/* <div  id="2fa" className='row g-5 gx-xxl-12'>
        <div className='col-xxl-3' style={{ color: '#344767' }} />
        <div className='col-xxl-9' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px' }}>Xác minh 2 bước</span>
              </div>
              <span style={{ marginBottom: '20px' }}>Sử dụng ứng dụng Google Authenticator</span>
              <br />
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '17px' }}>Ứng dụng xác thực</span>
                <Space style={{ marginLeft: 'auto', marginBottom: '20px' }}>
                  <span style={{ fontSize: '17px', color: '#A6B0C2' }}>Chưa cài đặt</span>
                  <Button type='primary' ghost>Cài đặt</Button>
                </Space>
              </div>
            </div>
          </div>
        </div>
        
      </div> */}
      <div id="login_session" className='row g-5 gx-xxl-12'>
        <div className='col-xxl-3' style={{ color: '#344767' }} />
        <div className='col-xxl-9' style={{ color: '#344767' }}>
          <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px' }}>Phiên Đăng Nhập</span>
              </div>
              <span style={{ marginBottom: '20px' }}>Đây là danh sách các thiết bị đã đăng nhập vào tài khoản của bạn. Xoá những phiên đăng nhập không phải là bạn.</span>
              <br />
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <DesktopOutlined style={{ fontSize: '32px', marginRight: '15px' }} />
                  <div>
                    <span>Phiên đăng nhập hiện tại</span>
                    <br />
                    <span>IP: 2405:4802:90a8:1820:d1c2:2b98:c212:1b58</span>
                  </div>
                </div>
                <Tag color="green" style={{ marginLeft: 'auto' }}>active</Tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountInformation;