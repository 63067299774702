const region = [
  {
    value: 'RANDOM',
    label: 'Random',
  },
  {
    value: 'VN',
    label: 'Việt Nam (VN)',
  },
  {
    value: 'AU',
    label: 'Australia (AU)',
  },
  {
    value: 'DZ',
    label: 'Algeria (DZ)',
  },
  {
    value: 'LT',
    label: 'Lithuania (LT)',
  },
  {
    value: 'AR',
    label: 'Argentina (AR)',
  },
  {
    value: 'SA',
    label: 'Saudi Arabia (SA)',
  },
  {
    value: 'PH',
    label: 'Philippines (PH)',
  },
  {
    value: 'CH',
    label: 'Switzerland (CH)',
  },
  {
    value: 'CA',
    label: 'Canada (CA)',
  },
  {
    value: 'IT',
    label: 'Italy (IT)',
  },
  {
    value: 'BR',
    label: 'Brazil (BR)',
  },
  {
    value: 'ES',
    label: 'Spain (ES)',
  },
  {
    value: 'TR',
    label: 'Turkey (TR)',
  },
  {
    value: 'NL',
    label: 'Netherlands (NL)',
  },
  {
    value: 'KW',
    label: 'Kuwait (KW)',
  },
  {
    value: 'AE',
    label: 'United Arab Emirates (AE)',
  },
  {
    value: 'US',
    label: 'United States (US)',
  },
  {
    value: 'GB',
    label: 'United Kingdom (GB)',
  },
  {
    value: 'DE',
    label: 'Germany (DE)',
  },
  {
    value: 'MX',
    label: 'Mexico (MX)',
  },
  {
    value: 'NP',
    label: 'Nepal (NP)',
  },
  {
    value: 'ID',
    label: 'Indonesia (ID)',
  },
  {
    value: 'TW',
    label: 'Taiwan (TW)',
  },
  {
    value: 'JP',
    label: 'Japan (JP)',
  },
  {
    value: 'KR',
    label: 'Korea (KR)',
  },
  {
    value: 'RU',
    label: 'Russia (RU)',
  },
  {
    value: 'SG',
    label: 'Singapore (SG)',
  },
  {
    value: 'FR',
    label: 'France (FR)',
  }
];

const appTiktok = [
  {
    value: 'android',
    label: 'Tiktok Android'
  },
  // {
  //   value: 'iphone',
  //   label: 'Tiktok iPhone'
  // },
  // {
  //   value: 'lite',
  //   label: 'Tiktok Lite'
  // },
];

const appVersion = [
  { value: '330303', label: 'Tiktok 33.3.3 (Có 815,468 điện thoại)' },
  { value: '310205', label: 'Tiktok 31.2.5 (Có 260,058 điện thoại)' },
  { value: '300704', label: 'Tiktok 30.7.4 (Có 507,144 điện thoại)' },
  { value: '300502', label: 'Tiktok 30.5.2 (Có 273,523 điện thoại)' },
  { value: '300304', label: 'Tiktok 30.3.4 (Có 440,849 điện thoại)' },
  { value: '290102', label: 'TikTok 29.1.2 (Có 360,570 điện thoại)' },
  { value: '280303', label: 'TikTok 28.3.3 (Có 478,729 điện thoại)' },
  { value: '270001', label: 'TikTok 27.0.1 (Có 365,819 điện thoại)' },
  { value: '260405', label: 'TikTok 26.4.5 (Có 356,243 điện thoại)' },
  { value: '250404', label: 'TikTok 25.4.4 (Có 368,583 điện thoại)' },
  { value: '250304', label: 'TikTok 25.3.4 (Có 431,897 điện thoại)' },
  { value: '250202', label: 'TikTok 25.2.2 (Có 437,275 điện thoại)' },
  { value: '250041', label: 'Tiktok 25.0.41 (Có 414,168 điện thoại)' },
  { value: '240303', label: 'Tiktok 24.3.3 (Có 409,629 điện thoại)' },
  { value: '230005', label: 'Tiktok 23.0.5 (Có 369,446 điện thoại)' },
  { value: '220605', label: 'Tiktok 22.6.5 (Có 373,372 điện thoại)' },
];

const proxyList = [
  { value: 'al', label: 'Proxy Albania (AL)' },
  { value: 'ar', label: 'Proxy Argentina (AR)' },
  { value: 'am', label: 'Proxy Armenia (AM)' },
  { value: 'au', label: 'Proxy Australia (AU)' },
  { value: 'at', label: 'Proxy Austria (AT)' },
  { value: 'az', label: 'Proxy Azerbaijan (AZ)' },
  { value: 'bd', label: 'Proxy Bangladesh (BD)' },
  { value: 'by', label: 'Proxy Belarus (BY)' },
  { value: 'be', label: 'Proxy Belgium (BE)' },
  { value: 'bo', label: 'Proxy Bolivia (BO)' },
  { value: 'ba', label: 'Proxy Bosnia And Herzegovina (BA)' },
  { value: 'br', label: 'Proxy Brazil (BR)' },
  { value: 'bg', label: 'Proxy Bulgaria (BG)' },
  { value: 'kh', label: 'Proxy Cambodia (KH)' },
  { value: 'ca', label: 'Proxy Canada (CA)' },
  { value: 'proxy_user', label: 'Proxy Cá Nhân (tự nhập)' },
  { value: 'cl', label: 'Proxy Chile (CL)' },
  { value: 'co', label: 'Proxy Colombia (CO)' },
  { value: 'cr', label: 'Proxy Costa Rica (CR)' },
  { value: 'hr', label: 'Proxy Croatia (HR)' },
  { value: 'cy', label: 'Proxy Cyprus (CY)' },
  { value: 'cz', label: 'Proxy Czech Republic (CZ)' },
  { value: 'dk', label: 'Proxy Denmark (DK)' },
  { value: 'do', label: 'Proxy Dominican Republic (DO)' },
  { value: 'ec', label: 'Proxy Ecuador (EC)' },
  { value: 'eg', label: 'Proxy Egypt (EG)' },
  { value: 'ee', label: 'Proxy Estonia (EE)' },
  { value: 'fi', label: 'Proxy Finland (FI)' },
  { value: 'fr', label: 'Proxy France (FR)' },
  { value: 'ge', label: 'Proxy Georgia (GE)' },
  { value: 'de', label: 'Proxy Germany (DE)' },
  { value: 'gr', label: 'Proxy Greece (GR)' },
  { value: 'hk', label: 'Proxy Hongkong (HK)' },
  { value: 'hu', label: 'Proxy Hungary (HU)' },
  { value: 'is', label: 'Proxy Iceland (IS)' },
  { value: 'in', label: 'Proxy India (IN)' },
  { value: 'id', label: 'Proxy Indonesia (ID)' },
  { value: 'iq', label: 'Proxy Iraq (IQ)' },
  { value: 'ie', label: 'Proxy Ireland (IE)' },
  { value: 'im', label: 'Proxy Isle of Man (IM)' },
  { value: 'il', label: 'Proxy Israel (IL)' },
  { value: 'it', label: 'Proxy Italy (IT)' },
  { value: 'jm', label: 'Proxy Jamaica (JM)' },
  { value: 'jp', label: 'Proxy Japan (JP)' },
  { value: 'jo', label: 'Proxy Jordan (JO)' },
  { value: 'kz', label: 'Proxy Kazakhstan (KZ)' },
  { value: 'ke', label: 'Proxy Kenya (KE)' },
  { value: 'kr', label: 'Proxy Korea (KR)' },
  { value: 'kw', label: 'Proxy Kuwait (KW)' },
  { value: 'kg', label: 'Proxy Kyrgyzstan (KG)' },
  { value: 'la', label: 'Proxy Laos (LA)' },
  { value: 'lv', label: 'Proxy Latvia (LV)' },
  { value: 'lt', label: 'Proxy Lithuania (LT)' },
  { value: 'lu', label: 'Proxy Luxembourg (LU)' },
  { value: 'mk', label: 'Proxy Macedonia (MK)' },
  { value: 'my', label: 'Proxy Malaysia (MY)' },
  { value: 'mx', label: 'Proxy Mexico (MX)' },
  { value: 'md', label: 'Proxy Moldova (MD)' },
  { value: 'ma', label: 'Proxy Morocco (MA)' },
  { value: 'nl', label: 'Proxy Netherlands (NL)' },
  { value: 'nz', label: 'Proxy New Zealand (NZ)' },
  { value: 'random', label: 'Proxy Ngẫu Nhiên 3G (Nên Dùng)' },
  { value: 'ng', label: 'Proxy Nigeria (NG)' },
  { value: 'no', label: 'Proxy Norway (NO)' },
  { value: 'om', label: 'Proxy Oman (OM)' },
  { value: 'pk', label: 'Proxy Pakistan (PK)' },
  { value: 'pa', label: 'Proxy Panama (PA)' },
  { value: 'pe', label: 'Proxy Peru (PE)' },
  { value: 'ph', label: 'Proxy Philippines (PH)' },
  { value: 'pl', label: 'Proxy Poland (PL)' },
  { value: 'pt', label: 'Proxy Portugal (PT)' },
  { value: 'ro', label: 'Proxy Romania (RO)' },
  { value: 'ru', label: 'Proxy Russia (RU)' },
  { value: 'sa', label: 'Proxy Saudi Arabia (SA)' },
  { value: 'rs', label: 'Proxy Serbia (RS)' },
  { value: 'sl', label: 'Proxy Sierra Leone (SL)' },
  { value: 'sg', label: 'Proxy Singapore (SG)' },
  { value: 'sk', label: 'Proxy Slovak Republic (SK)' },
  { value: 'si', label: 'Proxy Slovenia (SI)' },
  { value: 'za', label: 'Proxy South Africa (ZA)' },
  { value: 'es', label: 'Proxy Spain (ES)' },
  { value: 'lk', label: 'Proxy Sri Lanka (LK)' },
  { value: 'se', label: 'Proxy Sweden (SE)' },
  { value: 'ch', label: 'Proxy Switzerland (CH)' },
  { value: 'tw', label: 'Proxy Taiwan (TW)' },
  { value: 'tj', label: 'Proxy Tajikstan (TJ)' },
  { value: 'th', label: 'Proxy Thailand (TH)' },
  { value: 'tn', label: 'Proxy Tunisia (TN)' },
  { value: 'tr', label: 'Proxy Turkey (TR)' },
  { value: 'tm', label: 'Proxy Turkmenistan (TM)' },
  { value: 'ua', label: 'Proxy Ukraine (UA)' },
  { value: 'ae', label: 'Proxy United Arab Emirates (AE)' },
  { value: 'gb', label: 'Proxy United Kingdom (GB)' },
  { value: 'us', label: 'Proxy United States (US)' },
  { value: 'uz', label: 'Proxy Uzbekistan (UZ)' },
  { value: 'vn', label: 'Proxy Việt Nam (VN)' },
];

const xuQuantity = [
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
  { value: '500', label: '500' },
  { value: '1000', label: '1000' },
  { value: '2000', label: '2000' },
  { value: '5000', label: '5000' },
  { value: '10000', label: '10000' },
];

const numPick = [
  { value: '1', label: '1' },
  { value: '5', label: '5' },
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
  { value: '100', label: '100' },
  { value: '200', label: '200' },
];

const banks = [
  {value:"ACB", label:"ACB - Ngân hàng TMCP Á Châu"},
  {value:"Agribank", label:"Agribank - Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam"},
  {value:"BIDV", label:"BIDV - Ngân hàng TMCP Đầu tư và Phát triển Việt Nam"},
  {value:"CAKE", label:"CAKE - Ngân hàng số Cake by VPBank"},
  {value:"DongABank", label:"DongA Bank - Ngân hàng TMCP Đông Á "},
  {value:"Vietcombank", label:"Vietcombank - Ngân hàng TMCP Ngoại thương Việt Nam"},
  {value:"VietinBank", label:"VietinBank - Ngân hàng TMCP Công Thương Việt Nam"},
  {value:"VIB", label:"VIB - Ngân hàng TMCP Quốc tế Việt Nam"},
  {value:"VPBank", label:"VPBank - Ngân hàng TMCP Việt Nam Thịnh Vượng"},
  {value:"Techcombank", label:"Techcombank - Ngân hàng TMCP Kỹ thương Việt Nam"},
  {value:"TPBank", label:"TPBank - Ngân hàng TMCP Tiên Phong"},
  {value:"MBBank", label:"MBBank - Ngân hàng Quân Đội"},
  {value:"MSB", label:"MSB - Ngân hàng TMCP Hàng Hải"},
  {value:"Sacombank", label:"Sacombank - Ngân hàng TMCP Sài Gòn Thương Tín"},
  {value:"SCB", label:"SCB - Ngân hàng TMCP Sài Gòn"},
  {value:"SHB", label:"SHB - Ngân hàng TMCP Sài Gòn - Hà Nội"},
  {value:"SaigonBank", label:"SaigonBank - Ngân hàng TMCP Sài Gòn Công Thương"},
  {value:"Eximbank", label:"Eximbank - Ngân hàng TMCP Xuất Nhập khẩu Việt Nam"},
  {value:"OCB", label:"OCB - Ngân hàng TMCP Phương Đông"},
  {value:"HDBank", label:"HDBank - Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh"},
];

export const Constant = {
  region,
  appTiktok,
  appVersion,
  proxyList,
  xuQuantity,
  numPick,
  banks
}