import { Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const CommonFunc = {
  isLoading: (state: any, actions: any) => {
    return !!Object.keys(actions).find(key => state[`isLoading_${actions[key]}`] === true);
  }
}

export const showModalConfirm = (
  title: string,
  content: string,
  onOk = () => console.log('onOk'),
  onCancel = () => console.log('onCancel')
) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    okText: 'OK',
    cancelText: 'Cancel',
    onOk,
    onCancel,
    centered: true,
  });
};