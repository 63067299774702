import {Action} from '@reduxjs/toolkit'
import _ from 'lodash'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, select} from 'redux-saga/effects'
import { UserRentMailModel } from '../../main/models/UserRentMailModel'
import { GoogleProfileModel } from '../models/GoogleProfileModel'
import {UserModel} from '../models/UserModel'
import {getUserByGoogleToken, getUserByLogin, getUserByToken, getUserProfile} from "./AuthCRUD";
import { UserWinXuModel } from '../../main/models/UserWinXuModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypesAuth = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',

  RentMailLogin: '[RentMailLogin] Action',
  WinXuLoginByEmailPassword: '[WinXuLoginByEmailPassword] Action',
  RentMailRegister: '[RentMailRegister] Action',
  UserRentMailRequested: '[Request User RentMail] Action',
  UserWinXuLoaded: '[UserWinXuLoaded] Action',
  UpdateIdToken: '[UpdateIdToken] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  idToken: undefined,
  googleProfile: undefined,
  userWinXu: undefined
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string,
  idToken?: string,
  googleProfile?: GoogleProfileModel,
  userWinXu?: UserRentMailModel
}

export const reducer = persistReducer(
    {storage, key: 'v100-demo1-auth', whitelist: ['user', 'accessToken', 'userWinXu', 'idToken', 'accountType']},
    (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
      switch (action.type) {
        case actionTypesAuth.Login: {
          const accessToken = action.payload?.accessToken
          return {accessToken, user: undefined}
        }

        case actionTypesAuth.RentMailLogin: {
          const accessToken = action.payload?.accessToken;
          // @ts-ignore
          const idToken = action.payload?.idToken;
          // @ts-ignore
          const accountType = action.payload.accountType;
          return {accessToken, idToken, accountType};
        }

        case actionTypesAuth.WinXuLoginByEmailPassword: {
          const user = action.payload?.user;
          // @ts-ignore
          const idToken = action.payload?.idToken;
          // @ts-ignore
          const accountType = action.payload.accountType;
          return {user, idToken, accountType};
        }

        case actionTypesAuth.Register: {
          const accessToken = action.payload?.accessToken;
          return {accessToken, user: undefined}
        }

        case actionTypesAuth.RentMailRegister: {
          const idToken = action.payload?.idToken
          // @ts-ignore
          const user = action.payload?.user;
          // @ts-ignore
          const accountType = action.payload.accountType;
          return {user, idToken, accountType};
        }

        case actionTypesAuth.Logout: {
          return initialAuthState;
        }

        case actionTypesAuth.UserLoaded: {
          const user = action.payload?.user
          return {...state, user}
        }

        case actionTypesAuth.UserWinXuLoaded: {
          const userWinXu = action.payload?.userWinXu
          return {...state, userWinXu}
        }

        case actionTypesAuth.SetUser: {
          const user = action.payload?.user
          return {...state, user}
        }

        case actionTypesAuth.UpdateIdToken: {
          const idToken = action.payload?.idToken
          return {...state, idToken}
        }

        default:
          return state
      }
    }
)

export const actions = {
  login: (accessToken: string) => ({type: actionTypesAuth.Login, payload: {accessToken}}),
  loginByGoogle: (accessToken: string, idToken: string) => ({type: actionTypesAuth.RentMailLogin, payload: {accessToken, idToken, accountType: 'googleAccount'}}),
  loginByEmailPassword: (user: {uid: string, email: string}, idToken: string) =>
    ({type: actionTypesAuth.WinXuLoginByEmailPassword, payload: { user, idToken, accountType: 'emailAccount' }}),
  registerByEmailPassword: (user: {uid: string, name: string, email: string}, idToken: string) =>
    ({type: actionTypesAuth.RentMailRegister, payload: { user, idToken, accountType: 'emailAccount' }}),
  register: (accessToken: string) => ({
    type: actionTypesAuth.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypesAuth.Logout}),
  requestUser: () => ({
    type: actionTypesAuth.UserRequested,
  }),
  requestUserRentMail: () => ({
    type: actionTypesAuth.UserRentMailRequested
  }),
  fulfillUser: (user: UserModel) => ({ type: actionTypesAuth.UserLoaded, payload: { user } }),
  fulfillUserWinXu: (userWinXu: UserWinXuModel) => ({type: actionTypesAuth.UserWinXuLoaded, payload: { userWinXu }}),
  setUser: (user: UserModel) => ({ type: actionTypesAuth.SetUser, payload: { user } }),
  updateIdToken: (idToken: string) => ({ type: actionTypesAuth.UpdateIdToken, payload: { idToken } })
}

export function* saga() {
  yield takeLatest(actionTypesAuth.Login, function* loginSaga() {
    yield put(actions.requestUser())
  });

  yield takeLatest(actionTypesAuth.RentMailLogin, function* rentMailloginSaga() {
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken;
    // @ts-ignore
    const token = yield select(getToken);
    const {data: user} = yield getUserByGoogleToken(token);
    yield put(actions.fulfillUser(user));
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypesAuth.WinXuLoginByEmailPassword, function* rentMailLoginByEmailPasswordSaga() {
    // @ts-ignore
    const { data } = yield getUserByLogin();
    yield put(actions.fulfillUserWinXu(data.data));
  });

  yield takeLatest(actionTypesAuth.RentMailRegister, function* rentMailRegisterSaga() {
    yield put(actions.requestUserRentMail());
  });

  yield takeLatest(actionTypesAuth.UserRentMailRequested, function* userRentMailRequested() {
    // @ts-ignore
    const getUser = (state) => state.auth.user;
    // @ts-ignore
    const getUserRentMail = (state) => state.auth.userRentMail;
    // @ts-ignore
    const getAccountType : string = (state) => state.auth.accountType;
    // @ts-ignore
    const user = yield select(getUser);
    // @ts-ignore
    const userRentMail = yield select(getUserRentMail);
    // @ts-ignore
    const accountType = yield select(getAccountType);
    const {data} = yield getUserProfile(_.get(user, 'name', _.get(userRentMail, 'name', '')), accountType);
    // @ts-ignore
    yield put(actions.fulfillUserWinXu(data.data));
  })

  yield takeLatest(actionTypesAuth.Register, function* registerSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypesAuth.UserRequested, function* userRequested() {
    // @ts-ignore
    const getToken = (state) => state.auth.accessToken;
    // @ts-ignore
    let token = yield select(getToken)
    const {data: user} = yield getUserByToken(token)
    yield put(actions.fulfillUser(user))
  })
}
