import { Tabs } from "antd";
import { MailOutlined, PhoneOutlined, GlobalOutlined } from '@ant-design/icons';
import CookieTokenTab from "./CookieTokenTab";

const AddTiktok = () => {
  return (
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-8' style={{ color: '#344767' }}>
        <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
          <span style={{ fontSize: '20px', marginBottom: '20px' }}>Thêm tài khoản Tiktok</span>
          <Tabs
            defaultActiveKey="cookie_token"
            type="card"
            size="large"
            items={[
              {
                label: <div><MailOutlined /><span>Email OTP</span></div>,
                key: 'email_otp',
                children: <>Email OTP</>,
                disabled: true
              },
              {
                label: <div><PhoneOutlined /><span>Số Điện Thoại</span></div>,
                key: 'phone',
                children: <>Số Điện Thoại</>,
                disabled: true
              },
              {
                label: <div><GlobalOutlined /><span>Cookie & Token</span></div>,
                key: 'cookie_token',
                children: <CookieTokenTab />,
              }
            ]}
          />
        </div>
      </div>
      <div className='col-xxl-4' style={{ color: '#344767' }}>
        <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
          <span style={{ fontSize: '20px', marginBottom: '20px' }}>Hướng dẫn lấy Cookie & Token</span>
          <ul style={{ fontSize: '20px', listStyleType: 'none' }}>
            <li>Tuyệt đối không được đăng xuất Tiktok sau khi lấy Cookie hoặc Token</li>
            <li>Tải tiện ích lấy Cookie trên PC: <strong><a style={{ color: 'red' }}>Multi Login</a></strong></li>
            <li>Xem Video hướng dẫn lấy Cookie trên PC: <strong><a style={{ color: 'red' }}>Tại Đây</a></strong></li>
            <li>
              Hướng dẫn lấy Token trên Mobile hoặc PC:
              <ul>
                <li>
                  B1: Đăng nhập vào <strong><a style={{ color: 'red' }}>Tiktok.com</a></strong>
                </li>
                <li>
                  B2: Truy cập vào link : <strong><a style={{ color: 'red' }}>Lấy Token</a></strong> & link lấy token acc clone US <strong><a style={{ color: 'red' }}>Lấy Token US</a></strong>
                </li>
                <li>
                  B3: Copy toàn bộ giá trị nhận được dán vào NapXu rồi đăng nhập
                </li>
                <li>
                  Token sẽ có dạng như sau : {`"token":"TSLSGOVB9KQZHCEBED"`}
                </li>
                <li>Token sẽ rất nhanh hết hạn vui lòng sử dụng ngay</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AddTiktok;