import {Dropdown, Image, Select, Space, Table, TableColumnsType, Tag, Tooltip } from "antd";
import Button from "antd-button-color";
import { DownOutlined, UpOutlined, PlusOutlined, ReloadOutlined, DeleteOutlined, DollarTwoTone } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import * as Flags from 'country-flag-icons/react/3x2'
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionsAddTiktok } from "../redux/AddTiktokRedux";
import { RootState } from "../../../../setup";
import { AccountTiktokModel } from "../models/AccountTiktokModel";
import { showModalConfirm } from "../../common/common";

const ListTiktokAccount = () => {
  const listAccountTiktok =
    useSelector<RootState>(({ addTiktok }) => addTiktok.listAccountTiktok, shallowEqual) as AccountTiktokModel[];

  const [isExpandAction, setExpandAction] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value: string) => {
    console.log('search:', value);
  };
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsAddTiktok.getTiktokAccounts());
  }, [dispatch]);

  const columns: TableColumnsType<AccountTiktokModel> = [
    {
      title: 'HÀNH ĐỘNG',
      dataIndex: 'uid',
      render: (text) => <>
        <Space>
          <Tooltip title={<><span>Thêm tài khoản sử dụng</span><br /><span>Quét Rương & Quét Túi</span></>}>
            <Button type="info" shape="circle" icon={<PlusOutlined />} />
          </Tooltip>
          <Tooltip title="Cập nhật lại tài khoản">
            <Button
              type="success"
              shape="circle"
              icon={<ReloadOutlined />}
              onClick={() => dispatch(actionsAddTiktok.refreshTiktokAccount(text))}
            />
          </Tooltip>
          <Tooltip title="Xóa tài khoản">
            <Button
              type="danger"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => showModalConfirm('Warning', 'Are you sure?', () => {
                dispatch(actionsAddTiktok.deleteTiktokAccount(text));
                return;
              })}
            />
          </Tooltip>
        </Space>
      </>
    },
    {
      title: 'TIKTOK',
      dataIndex: 'account',
      width: '20%',
      render: (text, account) => <>
        <div style={{ display: 'flex', flexDirection: 'row', margin: '-10px' }}>
          <div style={{ width: '40px', height: '40px' }}>
            <Image src={account.avatar} preview={false} />
          </div>
          <div style={{ marginLeft: '10px' }}>
            <span>{account.name}</span>
            <br />
            <span style={{ color: '#8E9CB3', fontSize: '12px' }}>@{account.user}</span>
          </div>
        </div>
      </>
    },
    {
      title: 'SỐ XU',
      dataIndex: 'balance',
      render: (text, acc) => <><Space><span>{acc.balance.toLocaleString('US')}</span><DollarTwoTone twoToneColor="#ffbf00" /></Space></>,
    },
    {
      title: 'CẤP ĐỘ',
      dataIndex: 'level',
      render: (text) => <Tag color="blue">LV.{text}</Tag>
    },
    {
      title: 'QUỐC GIA',
      dataIndex: 'country',
      render: (text) => {
        // @ts-ignore
        const Flag = Flags[`${text}`];
        return <Flag style={{ width: '30px' }} />;
      }
    },
    {
      title: 'TRẠNG THÁI',
      dataIndex: 'status',
      render: (text) => text === 'live' ? <Tag color="green">LIVE</Tag> : <Tag color="red">DIE</Tag>
    },
    {
      title: 'NGÀY THÊM',
      dataIndex: 'date', 
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss')
    },
  ];

  return (
    <div className='col-xxl-12' style={{ color: '#344767' }}>
      <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
        <span style={{ fontSize: '20px', marginBottom: '20px', color: '#344767' }}>Danh Sách Tài Khoản</span>
        <span style={{ color: '#8897AF' }}>Lọc Theo Quốc Gia</span>
        <Select
          showSearch
          placeholder="Nhập Quốc Gia bạn muốn tìm kiếm"
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={[
            {
              value: 'all',
              label: 'Tất cả - 2 ID (Tổng 3,253 xu)',
            },
            {
              value: 'fr',
              label: 'Fran - 1 ID (Tổng 0 xu)',
            },
            {
              value: 'vn',
              label: 'VN - 1 ID (Tổng 3,253 xu)',
            },
          ]}
        />
          <Dropdown
            menu=
            {
              {
                items:
                  [
                    {
                      key: '1',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Cập nhật tài khoản đã chọn
                        </a>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Thêm tài khoản đã chọn để quét rương
                        </a>
                      ),
                    },
                    {
                      key: '3',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Xóa tài khoản đã chọn
                        </a>
                      ),
                    },
                    {
                      key: '4',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Xóa các tài khoản DIE
                        </a>
                      ),
                    },
                    {
                      key: '5',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Xóa các tài khoản 0 xu
                        </a>
                      ),
                    },
                    {
                      key: '6',
                      label: (
                        <a target="_blank" rel="noopener noreferrer" onClick={() => console.log()}>
                          Xóa toàn bộ tài khoản
                        </a>
                      ),
                    },
                  ]
              }
            }
            placement="bottom"
            arrow
            trigger={['click']}
          >
            <Button type="primary" style={{ marginTop: '10px', marginBottom: '20px', width: '25%' }} onClick={() => setExpandAction(!isExpandAction)}>
              <Space>
                Cài đặt thêm
                {isExpandAction ? <DownOutlined /> : <UpOutlined />}
              </Space>
            </Button>
        </Dropdown>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listAccountTiktok}
          rowKey='uid'
        />
      </div>
    </div>
  );
};

export default ListTiktokAccount;
