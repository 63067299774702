import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import _ from 'lodash';
import { ActionWithPayload } from '../../auth';
import UpdateProfilePayload from '../security/models/UpdateProfilePayload';
import { put, select, takeLatest } from 'redux-saga/effects';
import { apiUpdateProfile } from './ProfileCRUD';
import { actions } from '../../auth/redux/AuthRedux';

export const profileActionTypes = {
  SetUpdatePasswordState: '[SetUpdatePasswordState] Action',
  SetUpdatePasswordLoading: '[SetUpdatePasswordLoading] Action',
  UpdateProfile: '[UpdateProfile] Action',
  UpdateProfileFinish: '[UpdateProfileFinish] Action',
}

const initialState: IProfileState = {
  updatePasswordState: undefined,
}

export interface IProfileState {
  updatePasswordState?: string | undefined
};

export const actionsProfile = {
  setUpdatePasswordState: (updatePasswordState: string | undefined) => ({ type: profileActionTypes.SetUpdatePasswordState, payload: { updatePasswordState }}),
  setUpdatePasswordLoading: (isLoading: boolean) => ({ type: profileActionTypes.SetUpdatePasswordLoading, payload: { isLoading }}),
  updateProfile: (profilePayload: UpdateProfilePayload) => ({ type: profileActionTypes.UpdateProfile, payload: { profilePayload } }),
  updateProfileFinish: () => ({ type: profileActionTypes.UpdateProfileFinish })
}

export const reducer = persistReducer(
  {storage, key: 'win_xu_profile', whitelist: []},
  (state: IProfileState = initialState, action: ActionWithPayload<IProfileState>) => {
    switch (action.type) {
      case profileActionTypes.SetUpdatePasswordState: {
        // @ts-ignore
        const { updatePasswordState } = action.payload;
        return { ...state, updatePasswordState };
      }
      case profileActionTypes.SetUpdatePasswordLoading: {
        // @ts-ignore
        const { isLoading } = action.payload;
        return { ...state, [`isLoading_${profileActionTypes.SetUpdatePasswordLoading}`]: isLoading };
      }
      case profileActionTypes.UpdateProfile: {
        // @ts-ignore
        const { profilePayload } = action.payload;
        return { ...state, profilePayload, [`isLoading_${profileActionTypes.UpdateProfile}`]: true };
      }
      case profileActionTypes.UpdateProfileFinish: {
        return { ...state, [`isLoading_${profileActionTypes.UpdateProfile}`]: false };
      }
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(profileActionTypes.UpdateProfile, function* updateProfileSaga() {
    // @ts-ignore
    const getProfilePayload = (state) => state.profile.profilePayload;
    const profilePayload: UpdateProfilePayload = yield select(getProfilePayload);
    // @ts-ignore
    const userProfile = yield apiUpdateProfile(profilePayload);
    if (_.get(userProfile, 'data.data._id')) {
      yield put(actions.fulfillUserWinXu(userProfile.data.data));
    }
    yield put(actionsProfile.updateProfileFinish());
  });
}
