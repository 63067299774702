import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../../app/modules/auth';
import * as addTiktok from '../../app/modules/addtiktok';
import * as treasurebox from '../../app/modules/treasurebox';
import * as sendgift from '../../app/modules/gift';
import * as profile from '../../app/modules/account';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  addTiktok: addTiktok.reducer,
  treasurebox: treasurebox.reducer,
  sendgift: sendgift.reducer,
  profile: profile.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), addTiktok.saga(), treasurebox.saga(), sendgift.saga(), profile.saga()])
}
