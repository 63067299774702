import clsx from 'clsx'
import _ from 'lodash'
import {FC} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { ProfileHeaderMenu } from './ProfileHeaderMenu'
import { KTSVG } from '../../../helpers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

type Props = {
  isShowMenu: boolean,
  setShowMenu: (isShow: boolean) => void
}

const Topbar: FC<Props> = ({ isShowMenu, setShowMenu }) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Quick links */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu wrapper */}
          {/* <a
              className={clsx(
                  'btn btn-icon btn-active-light-primary btn-custom',
                  toolbarButtonHeightClass
              )}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              href='http://m.me/winmail38'
              target="_blank"
          >
            <KTSVG
                path='/media/icons/duotune/social/facebook-messenger.svg'
                className={toolbarButtonIconSizeClass}
            />
          </a> */}
          {/* <QuickLinks /> */}
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        >
          <div
            className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
          >
            <div
              className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
              id='kt_header_menu_mobile_toggle'
              style={{ marginRight: '10px' }}
              onClick={() => setShowMenu(!isShowMenu)}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
            </div>
          </div>
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={_.get(user, 'picture', 'https://preview.keenthemes.com/metronic/theme/html/demo1/dist/assets/media/svg/icons/General/User.svg')}
              alt='metronic'
            />
          </div>
          <ProfileHeaderMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
