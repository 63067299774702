import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../setup';
import _ from 'lodash';
import MainPage from '../modules/main/MainPage';
import Treasurebox from '../modules/treasurebox/Treasurebox';
import Account from '../modules/addtiktok/Account';
import SendGift from '../modules/gift/SendGift';
import AccountInformation from '../modules/account/security/component/AccountInformation';
import Payment from '../modules/account/transaction/component/Payment';
    
const PrivateRoutes = () => {
    const isAdmin = useSelector<RootState>(({auth}) => _.get(auth, 'userRentMail.isAdmin'), shallowEqual);
    return (
			<Routes>
				<Route element={<MasterLayout />}>
					{/* Redirect to Dashboard after success login/registartion */}
					<Route path='auth/*' element={<Navigate to='/main' />} />
					{/* Pages */}
					<Route path='main' element={<MainPage />} />
					<Route path='account' element={<Account />} />
					<Route path='treasurebox' element={<Treasurebox />} />
					<Route path='sendgift' element={<SendGift />} />
					<Route path='accountsecurity' element={<AccountInformation />} />
					{/* <Route path='payment' element={<Payment />} /> */}
					{/* Page Not Found */}
					<Route path='*' element={<Navigate to='/error/404' />} />
				</Route>
			</Routes>
    )
}

export { PrivateRoutes }
