import { AutoComplete, Button, Col, Image, Input, Radio, Row, Select, Space, Switch, Tag } from "antd";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Constant } from "../../Constant";
import { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actionsTreasurebox } from "./redux/TreasureboxRedux";
import { RootState } from "../../../setup";
import _ from "lodash";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { UserWinXuModel } from "../main/models/UserWinXuModel";
import { actionsAddTiktok } from "../addtiktok";
import { AccountTiktokModel } from "../addtiktok/models/AccountTiktokModel";
import GetLiveResponse from "./models/GetLiveResponse";
import localStorage from "redux-persist/es/storage";
import GetTiktokVersionResponse from "./models/GetTiktokVersionResponse";

const validationSchema = Yup.object().shape({
  participateWay: Yup.string(),
  contentReceiveXu: Yup.string().when('participateWay', {
    is: (participateWay: string) => participateWay === 'send_cmt',
    then: Yup.string().required('Vui lòng nhập nội dung để bình luận nhận Túi Quà')
  }),
  dropChestGift: Yup.string(),
  xuQuantity: Yup.number().when('dropChestGift', {
    is: (dropChestGift: string) => dropChestGift !== 'drop_gift_bags',
    then: Yup.number().min(20, 'Số xu cần thả rương phải lớn hơn 20')
      .max(10000, 'Số xu cần thả rương phải nhỏ hơn 10000')
  }),
  numPick: Yup.number().min(1, 'Số người nhập rương phải lớn hơn 1')
    .max(500, 'Số người nhập rương phải nhỏ hơn 500'),
  numSend: Yup.number().min(1, 'Số lần thả rương tối thiểu là 1')
    .max(10, 'Số lần thả rương tối đa là 10')
});

const Treasurebox = () => {
  const listAccountTiktok =
    useSelector<RootState>(({ addTiktok }) => addTiktok.listAccountTiktok, shallowEqual) as AccountTiktokModel[];
  const liveResponse: GetLiveResponse =
    useSelector<RootState>(({ treasurebox }) => treasurebox.liveResponse, shallowEqual) as GetLiveResponse;
  const tiktokVersionsResponse: GetTiktokVersionResponse[] =
    useSelector<RootState>(({ treasurebox }) => treasurebox.tiktokVersionsResponse, shallowEqual) as GetTiktokVersionResponse[];
  const userWinXu: UserWinXuModel =
    useSelector<RootState>(({auth}) => auth.userWinXu, shallowEqual) as UserWinXuModel;

  const [accounts, setAccounts] = useState<string | string[]>();
  const [usernameLive, setUsernameLive] = useState('');
  const [appRegion, setAppRegion] = useState('RANDOM');
  const [appTiktok, setAppTiktok] = useState('android');
  const [appVersion, setAppVersion] = useState('');
  const [proxy, setProxy] = useState('');
  const [dropMode, setDropMode] = useState('drop_chest_sv1');
  const [participateWay, setParticipateWay] = useState('');
  const [xuQuantity, setXuQuantity] = useState(20);
  const [numPick, setNumPick] = useState(5);
  const [timeBox, setTimeBox] = useState(3);
  const [serverApi, setServerApi] = useState('new');
  const [isNewDevice, setNewDevice] = useState(false);
  const [usernameTiktokOptions, setUsernameTiktokOptions] = useState<{ value: string }[]>([]);

  const formik = useFormik({
    initialValues: {
      participateWay: '',
      contentReceiveXu: '',
      xuQuantity: 100,
      numPick: 1,
      serverApi: 'new',
      numSend: 1,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: () => { console.log('onSubmit') }
  });

  const totalXu = useMemo(() => {
    const { xuQuantity, numSend } = formik.values;
    return (xuQuantity * numSend) + (isNewDevice ? 1000 : 0)
  }, [formik.values, isNewDevice]);

  useEffect(() => {
    dropMode === 'drop_gift_bags' && formik.setFieldValue('participateWay', participateWay || 'send_cmt');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropMode, participateWay]);

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  useEffect(() => {
    formik.setFieldValue('xuQuantity', xuQuantity);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xuQuantity]);

  useEffect(() => {
    formik.setFieldValue('numPick', numPick);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPick]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionsAddTiktok.getTiktokAccounts());
    dispatch(actionsTreasurebox.getTiktokVersions());
  }, [dispatch]);

  useEffect(() => {
    const getUsernameTiktoks = async () => {
      let usernameTiktoks = await localStorage.getItem('username_tiktok');
      if (!usernameTiktoks) {
        return;
      }
      setUsernameTiktokOptions(_.uniq(usernameTiktoks.split(',')).filter(u => !!u).map(u => ({ value: u })));
    }
    getUsernameTiktoks();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tiktokVersionsResponse)) {
      // @ts-ignore
      setAppVersion(tiktokVersionsResponse[0].value);
    }
  }, [tiktokVersionsResponse]);

  return (
    <>
      <div style={{ marginTop: '20px', marginBottom: '60px', color: 'white', fontSize: '20px' }}>Main Page</div>
      <div className='row g-5 gx-xxl-8 spinner spinner-primary mr-15'>
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-xxl-5`}>
            <div className="card-body py-3 fw-bold fs-6" style={{ height: '120px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ width: '100px', height: '100px', padding: '10px', border: '1px solid #ffffff', borderRadius: '10px', boxShadow: '0px 0px 24px 0px #C5C5C5' }}>
              <Image style={{ maxHeight: '90px' }} src={userWinXu?.image ? `data:image/png;base64,${userWinXu?.image}` : toAbsoluteUrl('/media/icons/duotune/xu/avatar.png')} preview={false} />
              </div>
              <div style={{ marginLeft: '10px', color: 'grey', alignItems: 'center' }}>
                <div>{_.get(userWinXu, 'name', '')}</div>
                <div>Thành viên</div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
              <div>{_.get(userWinXu, 'balance', '')}</div>
                <br />
                <span>Số dư</span>
              </div>
            </div>
          </div>
        </div>
        <div className='row g-5 gx-xxl-12'>
          <div className='col-xxl-8' style={{ color: '#344767' }}>
            <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <span style={{ fontSize: '20px', marginBottom: '20px' }}>Thả Rương & Túi Quà</span>
                <div style={{ marginLeft: 'auto', marginBottom: '20px' }}>
                  <Space><Switch />Lưu cài đặt</Space>
                </div>
              </div>
              <Space direction="vertical">
                <span>Chọn tài khoản <InfoCircleOutlined /></span>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  size="large"
                  onChange={(value) => setAccounts(value)}
                  options={(listAccountTiktok || []).map(ac => ({
                    value: ac.uid,
                    label: `${ac.name} (${ac.country}) (${ac.balance})`
                  }))}
                />
              </Space>
              <br />
              <Space direction="vertical">
                <span>Username Tiktok</span>
                <AutoComplete
                  style={{ width: '100%' }}
                  size="large"
                  options={usernameTiktokOptions}
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={usernameLive}
                  onChange={(value) => setUsernameLive(value)}
                />
              </Space>
              <br />
              <Button
                type="primary"
                size="large"
                disabled={!_.trim(usernameLive)}
                onClick={async () => {
                  let usernameTiktoks = await localStorage.getItem('username_tiktok');
                  if (!usernameTiktoks) {
                    usernameTiktoks = '';
                  }
                  usernameTiktoks = `${usernameLive},${usernameTiktoks}`;
                  const usernameTiktokArr = _.uniq(usernameTiktoks.split(',')).slice(0, 10);
                  await localStorage.setItem('username_tiktok', usernameTiktokArr.join(','));
                  setUsernameTiktokOptions(usernameTiktokArr.filter(u => !!u).map(u => ({ value: u })));
                  dispatch(actionsTreasurebox.getLive(usernameLive));
                }}
              >
                LẤY ID IDOL VÀ ROOM LIVE
              </Button>
              <br />
              <Row>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Tên Tiktok</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'nickname', 'Tên tiktok Idol sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Theo Dõi</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'follow', 'Số theo dõi Idol sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
                <Col span="8">
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <span>Room Live</span>
                    <div
                      style={{
                        width: '90%',
                        height: '40px',
                        border: '1px solid #808080',
                        borderRadius: '8px',
                        paddingTop: '10px',
                        color: '#BFBFBF',
                        paddingLeft: '5px'
                      }}>
                      {_.get(liveResponse, 'idlive', 'ID ROOM Live sẽ hiện ở đây')}
                    </div>
                  </Space>
                </Col>
              </Row>
              <br />
              <Space direction="vertical">
                <span>App country</span>
                <Row>
                  <Select
                    showSearch
                    style={{ width: '80%' }}
                    size="large"
                    placeholder="Search to Select"
                    defaultValue={appRegion}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={Constant.region}
                    onSelect={(value) => setAppRegion(value)}
                  />
                  <div style={{ width: '20%', paddingLeft: '20px' }}>
                    <Button
                      type="primary"
                      style={{ width: '100%' }}
                      size="large"
                      onClick={() => dispatch(actionsTreasurebox.getCreateDevice(appRegion))}
                    >
                      Tạo device mới
                    </Button>
                  </div>
                </Row>
              </Space>
              <br />
              <Space direction="vertical">
                <span>App Tiktok</span>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  defaultValue={appTiktok}
                  options={Constant.appTiktok}
                  onSelect={(value) => setAppTiktok(value)}
                />
              </Space>
              <br />
              <Space direction="vertical">
                <span>Version</span>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  value={appVersion}
                  options={(tiktokVersionsResponse || []).map(t => ({ value: t.value, label: t.name }))}
                  onSelect={(value) => setAppVersion(value)}
                />
              </Space>
              <br />
              <Space direction="vertical">
                <span>Proxy</span>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  size="large"
                  placeholder="Search to Select"
                  defaultValue=''
                  value={proxy}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={Constant.proxyList}
                  onSelect={(value) => setProxy(value)}
                />
              </Space>
              {/* <Radio.Group onChange={(e) => setDropMode(e.target.value)} value={dropMode}>
                <Space direction="vertical">
                  <Radio disabled value='drop_chest_sv1'>Thả Rương sv1 <Tag color="blue">1 Đ/XU</Tag></Radio>
                  <Radio disabled value='drop_chest_sv2'>Thả Rương sv2 <Tag color="blue">2 Đ/XU</Tag> trên 5k <Tag color="blue">1 Đ/XU</Tag></Radio>
                  <Radio disabled value='drop_gift_bags'>Thả Túi Quà <Tag color="blue">1 Đ/XU</Tag> trên 5k <Tag color="blue">0.5 Đ/XU</Tag></Radio>
                </Space>
              </Radio.Group> */}
              {dropMode.includes('drop_gift') ? <>
                <br />
                <Space direction="vertical">
                  <span>Cách tham gia</span>
                  <Select
                    style={{ width: '100%' }}
                    size="large"
                    defaultValue='send_cmt'
                    options={[
                      {
                        value: 'send_cmt',
                        label: 'Gửi bình luận này'
                      },
                      {
                        value: 'share_live',
                        label: 'Chia sẻ phiên LIVE này'
                      },
                      {
                        value: 'no_type',
                        label: 'Không có yêu cầu'
                      },
                    ]}
                    onSelect={(value) => setParticipateWay(value)}
                  />
                </Space>
                <br />
                <Space direction="vertical">
                  <span>Nội dung nhận xu</span>
                  <Input size="large" placeholder="Nhập nội dung để nhận xu" {...formik.getFieldProps('contentReceiveXu')} />
                  {(formik.touched.contentReceiveXu && formik.errors.contentReceiveXu) ? (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.contentReceiveXu}</span>
                      </div>
                    </div>
                  ) : <span style={{ color: '#969696' }}>(Nhập nội dung không có dấu và ký tự đặc biệt)</span>}
                </Space>
              </> : null}
              <br />
              <Space direction="vertical">
                <span>Số lượng xu</span>
                {dropMode !== 'drop_gift_bags' ? <Input
                  type="number"
                  size="large"
                  min={0}
                  defaultValue={100}
                  {...formik.getFieldProps('xuQuantity')}
                /> : <Select
                        style={{ width: '100%' }}
                        size="large"
                        defaultValue={xuQuantity}
                        options={Constant.xuQuantity}
                        onSelect={(value) => setXuQuantity(value)}
                      />
                    }
                {(formik.touched.xuQuantity && formik.errors.xuQuantity) && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.xuQuantity}</span>
                    </div>
                  </div>
                )}
              </Space>
              <br />
              <Space direction="vertical">
                <span>Số người nhặt</span>
                {dropMode !== 'drop_gift_bags' ? <Input
                  type="number"
                  size="large"
                  min={1}
                  defaultValue={5}
                  {...formik.getFieldProps('numPick')}
                /> : <Select
                        style={{ width: '100%' }}
                        size="large"
                        defaultValue={numPick}
                        options={Constant.numPick}
                        onSelect={(value) => setNumPick(value)}
                      />
                    }
                {(formik.touched.numPick && formik.errors.numPick) && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numPick}</span>
                    </div>
                  </div>
                )}
              </Space>
              <br />
              <Space direction="vertical">
                <span>Thời gian</span>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  defaultValue={timeBox}
                  options={[
                    {
                      value: 1,
                      label: '1 phút'
                    },
                    {
                      value: 2,
                      label: '2 phút'
                    },
                    {
                      value: 3,
                      label: '3 phút'
                    },
                    {
                      value: 4,
                      label: '4 phút'
                    },
                    {
                      value: 5,
                      label: '5 phút'
                    },
                  ]}
                  onSelect={(value) => setTimeBox(value)}
                />
              </Space>
              <br />
              {/* <Space direction="vertical">
                <span>Server API</span>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  defaultValue={serverApi}
                  options={[
                    {
                      value: 'new',
                      label: 'API Tiktok Mới'
                    },
                    {
                      value: 'old',
                      label: 'API Tiktok Cũ'
                    },
                  ]}
                  onSelect={(value) => setServerApi(value)}
                />
              </Space>
              <br />
              <Space direction="vertical">
                <span>Spam <InfoCircleOutlined /></span>
                <Space><Switch disabled />Spam Rương & Túi Quà</Space>
              </Space>
              <br />
              <Space direction="vertical">
                <span>Device  <InfoCircleOutlined /></span>
                <Space><Switch disabled checked={isNewDevice} onChange={(checked: boolean) => setNewDevice(checked)} />Tạo Điện Thoại Mới (1,000đ)</Space>
              </Space>
              <br /> */}
              <Space direction="vertical">
                <span>Số lần spam</span>
                <Input
                  type="number"
                  min={1}
                  max={100}
                  defaultValue={1}
                  size="large"
                  {...formik.getFieldProps('numSend')}
                />
                {(formik.touched.numSend && formik.errors.numSend) ? (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.numSend}</span>
                    </div>
                  </div>
                ) : <div style={{ color: '#6774AB' }}>Tối thiểu <span style={{ fontWeight: 'bold' }}>1</span> lần và tối đa <span style={{ fontWeight: 'bold' }}>10</span> lần</div>}
              </Space>
              <br />
              <div style={{ width: '100%', height: '60px', border: '1px solid #F8F9FA', borderRadius: '10px', backgroundColor: '#F8F9FA', textAlign: 'center' }}>
                <div style={{ marginTop: '12px', fontSize: '30px' }}>Tổng : <span style={{ fontSize: '30px', color: '#2192FE' }}>{totalXu}</span></div>
              </div>
              <br />
              <Button type="primary" size="large" disabled={!liveResponse || _.isEmpty(accounts)} onClick={async () => {
                await formik.submitForm();
                if (_.isEmpty(formik.errors)) {
                  const sendBoxPayload = {
                    user: _.isArray(accounts) ? accounts.join(',') : accounts,
                    roomId: liveResponse.idlive,
                    appCountry: appRegion,
                    appVersion: appVersion,
                    proxyCountry: proxy,
                    amountCoin: formik.values.xuQuantity,
                    amountPeople: formik.values.numPick,
                    time: timeBox,
                    amountSend: formik.values.numSend
                  }
                  dispatch(actionsTreasurebox.sendBox(sendBoxPayload));
                }
              }}>THẢ RƯƠNG & TÚI QUÀ</Button>
            </div>
          </div>
          <div className='col-xxl-4' style={{ color: '#344767' }}>
            <div className="card card-body card-xxl-stretch py-3 mb-xxl-5" style={{ marginTop: '0px' }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Treasurebox;
