import axios from 'axios';
import _ from 'lodash';
import UpdateProfilePayload from '../security/models/UpdateProfilePayload';

const WIN_XU_API_URL = process.env.REACT_APP_WIN_XU_API_URL;

const PUT_UPDATE_PROFILE = `${WIN_XU_API_URL}/api/profile`;
const GET_PAYMENT_CODE = `${WIN_XU_API_URL}/api/payment-code`;

export const apiUpdateProfile = (payload: UpdateProfilePayload) => {
  return axios.put(PUT_UPDATE_PROFILE, payload);
}

export const apiGetPaymentCode = () => {
  return axios.get(GET_PAYMENT_CODE);
}
