import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import XuMenu from './components/header/XuMenu'
import { Footer } from './components/Footer'
import HeaderWrapper from './components/header/HeaderWrapper'

const MasterLayout: React.FC = ({children}) => {
  const {classes} = useLayout()
  const [isShowMenu, setShowMenu] = useState(true);

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        {isShowMenu ? <XuMenu /> : null}
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper isShowMenu={isShowMenu} setShowMenu={setShowMenu} />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            {/* <Toolbar /> */}
            {/* <RentMailToolbar /> */}
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
              style={{ maxWidth: '100%' }}
            >
              {/* <AsideDefault /> */}
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  )
}

export {MasterLayout}
