import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import { notification } from 'antd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../setup'
import { sendGiftActionTypes, actionsSendGift } from './modules/gift'
import { actionsTreasurebox, treasureboxActionTypes } from './modules/treasurebox'
import { CommonFunc } from './modules/common/common'
import { Loading } from './modules/common/component/Loading'
import { actionsProfile, profileActionTypes } from './modules/account'

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const App = () => {
  const isSendGiftSuccess: boolean =
    useSelector<RootState>(({ sendgift }) => sendgift.sendGiftSuccess, shallowEqual) as boolean;
  const isSendBoxSuccess: boolean =
    useSelector<RootState>(({ treasurebox }) => treasurebox.sendBoxSuccess, shallowEqual) as boolean;
  const sendBoxMessage: string =
    useSelector<RootState>(({ treasurebox }) => treasurebox.sendBoxMessage, shallowEqual) as string;
  const isCreateDeviceSuccess: boolean =
    useSelector<RootState>(({ treasurebox }) => treasurebox.createDeviceSuccess, shallowEqual) as boolean;
  const updatePasswordState: string =
    useSelector<RootState>(({ profile }) => profile.updatePasswordState, shallowEqual) as string;

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, description: string, onClose = () => {}) => {
    api[type]({
      message: type === 'success' ? 'Thành công' : type === 'error' ? 'Thất bại' : 'Có lỗi',
      description,
      duration: 4,
      onClose
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('sdsd',isSendBoxSuccess, sendBoxMessage)
    if (isSendGiftSuccess === true) {
      openNotificationWithIcon('success', 'Tặng quà thành công!', () => dispatch(actionsSendGift.resetStatusSendGift()));
    } else if (isSendBoxSuccess === true) {
      openNotificationWithIcon('success', 'Thả rương thành công!', () => dispatch(actionsTreasurebox.resetStatusSendBox()));
    } else if (isSendBoxSuccess === false) {
      openNotificationWithIcon('error', sendBoxMessage || 'Thả rương thất bại!', () => dispatch(actionsTreasurebox.resetStatusSendBox()));
    } else if (updatePasswordState === 'success') {
      openNotificationWithIcon('success', 'Cập nhật password thành công!', () => dispatch(actionsProfile.setUpdatePasswordState(undefined)));
    } else if (updatePasswordState === 'error') {
      openNotificationWithIcon('error', 'Cập nhật password thất bại!', () => dispatch(actionsProfile.setUpdatePasswordState(undefined)));
    } else if (isCreateDeviceSuccess === true) {
      openNotificationWithIcon('success', 'Tạo device thành công!', () => dispatch(actionsProfile.setUpdatePasswordState(undefined)));
    } else if (isCreateDeviceSuccess === false) {
      openNotificationWithIcon('error', 'Tạo device thất bại!', () => dispatch(actionsProfile.setUpdatePasswordState(undefined)));
    }
  }, [isSendGiftSuccess, isSendBoxSuccess, updatePasswordState, isCreateDeviceSuccess]);

  const isLoading: boolean =
    useSelector<RootState>(({ sendgift, treasurebox, profile }) => CommonFunc.isLoading({ ...sendgift, ...treasurebox, ...profile }, { ...sendGiftActionTypes, ...treasureboxActionTypes, ...profileActionTypes }), shallowEqual) as boolean;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Loading isLoading={isLoading} />
      {contextHolder}
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}