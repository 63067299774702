import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { ActionWithPayload } from '../../auth';
import _ from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import { apiGetGifts, apiGetLive, apiGetUser, apiSendGift } from './SendGiftCRUD';
import GetLiveResponse from '../models/GetLiveResponse';
import GetGiftsResponse from '../models/GetGiftsResponse';
import { SendGiftPayload } from '../models/SendGiftPayload';
import { GetUserResponse } from '../models/GetUserResponse';

export const sendGiftActionTypes = {
  GetGifts: '[SendGift_GetGifts] Action',
  GetGiftsFinish: '[SendGift_GetGiftsFinish] Action',
  GetLive: '[SendGift_GetLive] Action',
  GetLiveFinish: '[SendGift_GetLiveFinish] Action',
  SendGift: '[SendGift_SendGift] Action',
  SendGiftFinish: '[SendGift_SendGiftFinish] Action',
  GetUser: '[SendGift_GetUser] Action',
  GetUserFinish: '[SendGift_GetUserFinish] Action',
  ResetStatusSendGift: '[SendGift_ResetStatusSendGift] Action',
}

const initialState: ITreasureboxState = {
  liveResponse: undefined,
  giftsResponse: [],
  userResponse: undefined,
  sendGiftSuccess: undefined,
}

export interface ITreasureboxState {
  giftsResponse?: GetGiftsResponse[],
  liveResponse?: GetLiveResponse,
  userResponse?: GetUserResponse,
  sendGiftSuccess?: boolean | undefined
};

export const actionsSendGift = {
  getGifts: () => ({ type: sendGiftActionTypes.GetGifts }),
  getGiftsFinish: (giftsResponse: GetGiftsResponse) => ({ type: sendGiftActionTypes.GetGiftsFinish, payload: { giftsResponse }}),
  getLive: (user: string) => ({ type: sendGiftActionTypes.GetLive, payload: { user }}),
  getLiveFinish: (liveResponse: GetLiveResponse) => ({ type: sendGiftActionTypes.GetLiveFinish, payload: { liveResponse }}),
  sendGift: (sendGiftPayload: SendGiftPayload) => ({ type: sendGiftActionTypes.SendGift, payload: { sendGiftPayload } }),
  sendGiftFinish: (isSuccess: boolean) => ({ type: sendGiftActionTypes.SendGiftFinish, payload: { isSendGiftSuccess: isSuccess } }),
  getUser: (username: string) => ({ type: sendGiftActionTypes.GetUser, payload: { username }  }),
  getUserFinish: (userResponse: GetUserResponse) => ({ type: sendGiftActionTypes.GetUserFinish, payload: { userResponse } }),
  resetStatusSendGift: () => ({ type: sendGiftActionTypes.ResetStatusSendGift })
}

export const reducer = persistReducer(
  {storage, key: 'win_xu_sendgift', whitelist: []},
  (state: ITreasureboxState = initialState, action: ActionWithPayload<ITreasureboxState>) => {
    switch (action.type) {
      case sendGiftActionTypes.GetGifts: {
        return { ...state, [`isLoading_${sendGiftActionTypes.GetGifts}`]: true };
      }
      case sendGiftActionTypes.GetGiftsFinish: {
        // @ts-ignore
        const { giftsResponse } = action.payload;
        return { ...state, giftsResponse, [`isLoading_${sendGiftActionTypes.GetGifts}`]: false };
      }
      case sendGiftActionTypes.GetLive: {
        // @ts-ignore
        const { user } = action.payload;
        return { ...state, user, [`isLoading_${sendGiftActionTypes.GetLive}`]: true };
      }
      case sendGiftActionTypes.GetLiveFinish: {
        // @ts-ignore
        const { liveResponse } = action.payload;
        return { ...state, liveResponse, [`isLoading_${sendGiftActionTypes.GetLive}`]: false };
      }
      case sendGiftActionTypes.SendGift: {
        // @ts-ignore
        const { sendGiftPayload } = action.payload;
        return { ...state, sendGiftPayload, [`isLoading_${sendGiftActionTypes.SendGift}`]: true };
      }
      case sendGiftActionTypes.SendGiftFinish: {
        // @ts-ignore
        const { isSendGiftSuccess } = action.payload;
        return { ...state, sendGiftSuccess: isSendGiftSuccess, [`isLoading_${sendGiftActionTypes.SendGift}`]: false };
      }
      case sendGiftActionTypes.GetUser: {
        // @ts-ignore
        const { username } = action.payload;
        return { ...state, username, [`isLoading_${sendGiftActionTypes.GetUser}`]: true };
      }
      case sendGiftActionTypes.GetUserFinish: {
        // @ts-ignore
        const { userResponse } = action.payload;
        return { ...state, userResponse, [`isLoading_${sendGiftActionTypes.GetUser}`]: false };
      }
      case sendGiftActionTypes.ResetStatusSendGift: {
        return { ...state, sendGiftSuccess: undefined }
      }
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(sendGiftActionTypes.GetGifts, function* getGiftsSaga() {
    // @ts-ignore
    const { data } = yield apiGetGifts();
    yield put(actionsSendGift.getGiftsFinish(data.data as GetGiftsResponse));
  });

  yield takeLatest(sendGiftActionTypes.GetLive, function* getLiveSaga() {
    // @ts-ignore
    const getUser = (state) => state.sendgift.user;
    const user: string = yield select(getUser);
    // @ts-ignore
    const { data } = yield apiGetLive(user);
    yield put(actionsSendGift.getLiveFinish(data.data as GetLiveResponse));
  })

  yield takeLatest(sendGiftActionTypes.SendGift, function* sendGiftSaga() {
    // @ts-ignore
    const getSendGiftPayload = (state) => state.sendgift.sendGiftPayload;
    const sendGiftPayload: SendGiftPayload = yield select(getSendGiftPayload);
    // @ts-ignore
    const { data } = yield apiSendGift(sendGiftPayload);
    yield put(actionsSendGift.sendGiftFinish(data.message === 'success'));
  })

  yield takeLatest(sendGiftActionTypes.GetUser, function* getUserSaga() {
    // @ts-ignore
    const getUsername = (state) => state.sendgift.username;
    const username: string = yield select(getUsername);
    // @ts-ignore
    const { data } = yield apiGetUser(username);
    yield put(actionsSendGift.getUserFinish(data.data));
  })
}
