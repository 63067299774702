import { Image } from "antd";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import _ from "lodash";
import { UserWinXuModel } from "../main/models/UserWinXuModel";
import { RootState } from "../../../setup";
import { shallowEqual, useSelector } from "react-redux";

const Header = () => {
  const userWinXu: UserWinXuModel =
    useSelector<RootState>(({auth}) => auth.userWinXu, shallowEqual) as UserWinXuModel;
  return (
    <>
      <div style={{ marginTop: '20px', marginBottom: '60px', color: 'white', fontSize: '20px' }}>Main Page</div>
      <div className='row g-5 gx-xxl-8 spinner spinner-primary mr-15'>
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-xxl-5`}>
            <div className="card-body py-3 fw-bold fs-6" style={{ height: '120px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ width: '100px', height: '100px', padding: '10px', border: '1px solid #ffffff', borderRadius: '10px', boxShadow: '0px 0px 24px 0px #C5C5C5' }}>
                <Image style={{ maxHeight: '90px' }} src={userWinXu?.image ? `data:image/png;base64,${userWinXu?.image}` : toAbsoluteUrl('/media/icons/duotune/xu/avatar.png')} preview={false} />
              </div>
              <div style={{ marginLeft: '10px', color: 'grey', alignItems: 'center' }}>
                <div>{_.get(userWinXu, 'name', '')}</div>
                <div>Thành viên</div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
              <div>{_.get(userWinXu, 'balance', '')}</div>
                <br />
                <span>Số dư</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;