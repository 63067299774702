import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { ActionWithPayload } from '../../auth';
import _ from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import { apiGetCreateDevice, apiGetLive, apiGetTiktokVersions, apiSendBox } from './TreasureboxCRUD';
import SendBoxPayload from '../models/SendBoxPayload';
import SendBoxResponse from '../models/SendBoxResponse';
import GetLiveResponse from '../models/GetLiveResponse';
import GetTiktokVersionResponse from '../models/GetTiktokVersionResponse';

export const treasureboxActionTypes = {
  GetLive: '[Treasurebox_GetLive] Action',
  GetLiveFinish: '[Treasurebox_GetLiveFinish] Action',
  GetTiktokVersions: '[Treasurebox_GetTiktokVersions] Action',
  GetTiktokVersionsFinish: '[Treasurebox_GetTiktokVersionsFinish] Action',
  GetCreateDevice: '[Treasurebox_GetCreateDevice] Action',
  GetCreateDeviceFinish: '[Treasurebox_GetCreateDeviceFinish] Action',
  SendBox: '[Treasurebox_SendBox] Action',
  SendBoxFinish: '[Treasurebox_SendBoxFinish] Action',
  ResetStatusSendBox: '[Treasurebox_ResetStatusSendBox] Action',
}

const initialState: ITreasureboxState = {
  liveResponse: undefined,
  tiktokVersionsResponse: [],
  sendBoxResponse: undefined,
  sendBoxSuccess: undefined,
  sendBoxMessage: '',
  createDeviceSuccess: undefined,
}

export interface ITreasureboxState {
  liveResponse?: GetLiveResponse,
  tiktokVersionsResponse?: GetTiktokVersionResponse[],
  sendBoxResponse?: SendBoxResponse,
  sendBoxSuccess?: boolean | undefined,
  sendBoxMessage?: string | '',
  createDeviceSuccess?: boolean | undefined,
};

export const actionsTreasurebox = {
  getLive: (user: string) => ({ type: treasureboxActionTypes.GetLive, payload: { user }}),
  getLiveFinish: (liveResponse: GetLiveResponse) => ({ type: treasureboxActionTypes.GetLiveFinish, payload: { liveResponse }}),
  getTiktokVersions: () => ({ type: treasureboxActionTypes.GetTiktokVersions }),
  getTiktokVersionsFinish: (tiktokVersionsResponse: GetTiktokVersionResponse[]) => ({ type: treasureboxActionTypes.GetTiktokVersionsFinish, payload: { tiktokVersionsResponse } }),
  getCreateDevice: (country: string) => ({ type: treasureboxActionTypes.GetCreateDevice, payload: { countryCreateDevice: country } }),
  getCreateDeviceFinish: (isSuccess: boolean) => ({ type: treasureboxActionTypes.GetCreateDeviceFinish, payload: { createDeviceSuccess: isSuccess } }),
  sendBox: (sendBoxPayload: SendBoxPayload) => ({ type: treasureboxActionTypes.SendBox, payload: { sendBoxPayload } }),
  sendBoxFinsish: (isSuccess: boolean, msg: string) => ({ type: treasureboxActionTypes.SendBoxFinish, payload: { isSendBoxSuccess: isSuccess, sendBoxMessage: msg } }),
  resetStatusSendBox: () => ({ type: treasureboxActionTypes.ResetStatusSendBox })
}

export const reducer = persistReducer(
  {storage, key: 'win_xu_treasurebox', whitelist: []},
  (state: ITreasureboxState = initialState, action: ActionWithPayload<ITreasureboxState>) => {
    switch (action.type) {
      case treasureboxActionTypes.GetLive: {
        // @ts-ignore
        const { user } = action.payload;
        return { ...state, user, [`isLoading_${treasureboxActionTypes.GetLive}`]: true };
      }
      case treasureboxActionTypes.GetLiveFinish: {
        // @ts-ignore
        const { liveResponse } = action.payload;
        return { ...state, liveResponse, [`isLoading_${treasureboxActionTypes.GetLive}`]: false };
      }
      case treasureboxActionTypes.GetTiktokVersions: {
        return { ...state, [`isLoading_${treasureboxActionTypes.GetTiktokVersions}`]: true };
      }
      case treasureboxActionTypes.GetTiktokVersionsFinish: {
        // @ts-ignore
        const { tiktokVersionsResponse } = action.payload;
        return { ...state, tiktokVersionsResponse, [`isLoading_${treasureboxActionTypes.GetTiktokVersions}`]: false };
      }
      case treasureboxActionTypes.GetCreateDevice: {
        // @ts-ignore
        const { countryCreateDevice } = action.payload;
        return { ...state, countryCreateDevice, [`isLoading_${treasureboxActionTypes.GetCreateDevice}`]: true };
      }
      case treasureboxActionTypes.GetCreateDeviceFinish: {
        // @ts-ignore
        const { createDeviceSuccess } = action.payload;
        return { ...state, createDeviceSuccess, [`isLoading_${treasureboxActionTypes.GetCreateDevice}`]: false };
      }
      case treasureboxActionTypes.SendBox: {
        // @ts-ignore
        const { sendBoxPayload } = action.payload;
        return { ...state, sendBoxPayload, [`isLoading_${treasureboxActionTypes.SendBox}`]: true };
      }
      case treasureboxActionTypes.SendBoxFinish: {
        // @ts-ignore
        const { isSendBoxSuccess, sendBoxMessage } = action.payload;
        return { ...state, sendBoxSuccess: isSendBoxSuccess, sendBoxMessage: sendBoxMessage, [`isLoading_${treasureboxActionTypes.SendBox}`]: false };
      }
      case treasureboxActionTypes.ResetStatusSendBox: {
        return { ...state, sendBoxSuccess: undefined }
      }
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(treasureboxActionTypes.GetTiktokVersions, function* getTiktokVersionsSaga() {
    // @ts-ignore
    const { data } = yield apiGetTiktokVersions();
    // @ts-ignore
    yield put(actionsTreasurebox.getTiktokVersionsFinish(data.data as GetTiktokVersionResponse[]));
  })

  yield takeLatest(treasureboxActionTypes.GetCreateDevice, function* getCreateDeviceSaga() {
    // @ts-ignore
    const getCountry = (state) => state.treasurebox.countryCreateDevice;
    const country: string = yield select(getCountry);
    // @ts-ignore
    const { data } = yield apiGetCreateDevice(country);
    // @ts-ignore
    yield put(actionsTreasurebox.getCreateDeviceFinish(data.status));
  })

  yield takeLatest(treasureboxActionTypes.GetLive, function* getLiveSaga() {
    // @ts-ignore
    const getUser = (state) => state.treasurebox.user;
    const user: string = yield select(getUser);
    // @ts-ignore
    const { data } = yield apiGetLive(user);
    yield put(actionsTreasurebox.getLiveFinish(data.data as GetLiveResponse));
  })

  yield takeLatest(treasureboxActionTypes.SendBox, function* checkLiveSaga() {
    // @ts-ignore
    const getSendBoxPayload = (state) => state.treasurebox.sendBoxPayload;
    const sendBoxPayload: SendBoxPayload = yield select(getSendBoxPayload);
    // @ts-ignore
    const { data } = yield apiSendBox(sendBoxPayload);
    yield put(actionsTreasurebox.sendBoxFinsish(data.status, data.message || ''));
  })
}

