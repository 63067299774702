import axios from 'axios'
import _ from 'lodash'
import SendBoxPayload from '../models/SendBoxPayload';

const WIN_XU_API_URL = process.env.REACT_APP_WIN_XU_API_URL;

const GET_TIKTOK_VERSIONS = `${WIN_XU_API_URL}/api/app-devices`;
const GET_CREATE_DEVICE = `${WIN_XU_API_URL}/api/create-device`;
const GET_GET_LIVE = `${WIN_XU_API_URL}/api/get-live`;
const POST_SEND_BOX = `${WIN_XU_API_URL}/api/send-treasure`;

export function apiGetLive(user: string) {
  return axios.get(GET_GET_LIVE, { params: { user } });
}

export function apiGetTiktokVersions() {
  return axios.get(GET_TIKTOK_VERSIONS);
}

export function apiGetCreateDevice(country: string) {
  return axios.get(GET_CREATE_DEVICE, { params: { country } });
}

export function apiSendBox(sendBoxPayload: SendBoxPayload) {
  return axios.post(POST_SEND_BOX, sendBoxPayload);
}
