import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { ActionWithPayload } from '../../auth';
import { put, select, takeLatest } from 'redux-saga/effects';
import AddTiktokPayload from '../models/AddTiktokPayload';
import { apiAddTiktok, apiDeleteTiktokAccount, apiGetTiktokAccounts, apiRefreshTiktokAccount } from './AddTiktokCRUD';
import { AccountTiktokModel } from '../models/AccountTiktokModel';

export const actionTypes = {
  GetTiktokAccounts: '[GetTiktokAccounts] Action',
  GetTiktokAccountsFinish: '[GetTiktokAccountsFinish] Action',
  AddTiktok: '[AddTiktok] Action',
  AddTiktokFinish: '[AddTiktokFinish] Action',
  DeleteTiktokAccount: '[DeleteTiktokAccount] Action',
  DeleteTiktokAccountFinish: '[DeleteTiktokAccountFinish] Action',
  RefreshTiktokAccount: '[RefreshTiktokAccount] Action',
  RefreshTiktokAccountFinish: '[RefreshTiktokAccountFinish] Action',
}

const initialState: IAddtiktokState = {
  listAccountTiktok: []
}

export interface IAddtiktokState {
  listAccountTiktok: AccountTiktokModel[];
};

export const actionsAddTiktok = {
  getTiktokAccounts: () => ({ type: actionTypes.GetTiktokAccounts }),
  getTiktokAccountsFinish: (accounts: AccountTiktokModel[]) => ({ type: actionTypes.GetTiktokAccountsFinish, payload: { accounts } }),
  addTiktok: (tiktokPayload: AddTiktokPayload) => ({ type: actionTypes.AddTiktok, payload: { tiktokPayload } }),
  addTiktokFinish: () => ({ type: actionTypes.AddTiktokFinish }),
  deleteTiktokAccount: (uid: string) => ({ type: actionTypes.DeleteTiktokAccount, payload: { uid } }),
  deleteTiktokAccountFinish: () => ({ type: actionTypes.DeleteTiktokAccountFinish }),
  refreshTiktokAccount: (uid: string) => ({ type: actionTypes.RefreshTiktokAccount, payload: { uid } }),
  refreshTiktokAccountFinish: () => ({ type: actionTypes.RefreshTiktokAccountFinish }),
}

export const reducer = persistReducer(
  {storage, key: 'win_xu_add_tiktok', whitelist: []},
  (state: IAddtiktokState = initialState, action: ActionWithPayload<IAddtiktokState>) => {
    switch (action.type) {
      case actionTypes.GetTiktokAccounts: {
        return { ...state, [`isLoading_${actionTypes.GetTiktokAccounts}`]: true };
      }
      case actionTypes.GetTiktokAccountsFinish: {
        // @ts-ignore
        const listAccountTiktok = action.payload.accounts;
        return { ...state, listAccountTiktok: listAccountTiktok, [`isLoading_${actionTypes.GetTiktokAccounts}`]: false };
      }
      case actionTypes.AddTiktok: {
        // @ts-ignore
        const tiktok = action.payload.tiktokPayload;
        return { ...state, tiktokPayload: tiktok, [`isLoading_${actionTypes.AddTiktok}`]: true };
      }
      case actionTypes.AddTiktokFinish: {
        return { ...state, [`isLoading_${actionTypes.AddTiktok}`]: false };
      }
      case actionTypes.DeleteTiktokAccount: {
        // @ts-ignore
        const uid = action.payload.uid;
        return { ...state, uid, [`isLoading_${actionTypes.DeleteTiktokAccount}`]: true };
      }
      case actionTypes.DeleteTiktokAccountFinish: {
        return { ...state, [`isLoading_${actionTypes.DeleteTiktokAccount}`]: false };
      }
      case actionTypes.RefreshTiktokAccount: {
        // @ts-ignore
        const uid = action.payload.uid;
        return { ...state, uid, [`isLoading_${actionTypes.RefreshTiktokAccount}`]: true };
      }
      case actionTypes.RefreshTiktokAccountFinish: {
        return { ...state, [`isLoading_${actionTypes.RefreshTiktokAccount}`]: false };
      }
    }
    return state;
  }
);

export function* saga() {
  yield takeLatest(actionTypes.AddTiktok, function* addTiktokSaga() {
    // @ts-ignore
    const getTiktokPayload = (state) => state.addTiktok.tiktokPayload;
    const tiktok: AddTiktokPayload = yield select(getTiktokPayload);
    yield apiAddTiktok(tiktok);
    yield put(actionsAddTiktok.addTiktokFinish());
    yield put(actionsAddTiktok.getTiktokAccounts());
  });

  yield takeLatest(actionTypes.GetTiktokAccounts, function* getTiktokAccountsSaga() {
    // @ts-ignore
    const { data } = yield apiGetTiktokAccounts();
    yield put(actionsAddTiktok.getTiktokAccountsFinish(data.data));
  });

  yield takeLatest(actionTypes.DeleteTiktokAccount, function* deleteTiktokAccountSaga() {
    // @ts-ignore
    const uidPayload = (state) => state.addTiktok.uid;
    const uid: string = yield select(uidPayload);
    yield apiDeleteTiktokAccount(uid);
    yield put(actionsAddTiktok.deleteTiktokAccountFinish());
    yield put(actionsAddTiktok.getTiktokAccounts());
  });

  yield takeLatest(actionTypes.RefreshTiktokAccount, function* refreshTiktokAccountSaga() {
    // @ts-ignore
    const uidPayload = (state) => state.addTiktok.uid;
    const uid: string = yield select(uidPayload);
    yield apiRefreshTiktokAccount(uid);
    yield put(actionsAddTiktok.refreshTiktokAccountFinish());
    yield put(actionsAddTiktok.getTiktokAccounts());
  });
}
