import React, { useEffect, useMemo, useState } from 'react';
import { UserOutlined, ToolOutlined, HomeOutlined, TikTokOutlined, DollarOutlined, UsergroupDeleteOutlined, BlockOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Divider, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useMediaQuery } from 'react-responsive';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem(<Link to="/main">Trang Chủ</Link>, 'home_page', <HomeOutlined />),

  getItem(<Link to="/account">Thêm Tiktok</Link>, 'account', <TikTokOutlined />),

  getItem(<Link to="/main">Nạp Xu Tiktok</Link>, 'add_tiktok_xu', <DollarOutlined />),

  getItem('Tài Khoản', 'account_manager', <UserOutlined />, [
    getItem(<Link to="/accountsecurity">Bảo Mật</Link>, 'account_security'),
    // getItem(<Link to="/payment">Nạp Tiền</Link>, 'account_trasaction'),
    getItem(<Link to="/main">Bán Xu</Link>, 'account_sell_xu'),
    getItem(<Link to="/main">Rút Tiền</Link>, 'account_withdraw'),
    getItem(<Link to="/main">Quỹ Bảo Hiểm</Link>, 'account_insurance_fund'),
    getItem(<Link to="/main">Lịch Sử Giao Dịch</Link>, 'transaction_history'),
  ]),

  getItem(<Link to="/main">Blacklist Userid</Link>, 'blacklist_userid', <UsergroupDeleteOutlined />),

  getItem(<Link to="/main">Chặn Follow Uid</Link>, 'block_follow_uid', <BlockOutlined />),

  getItem('Mua bán tiktok', 'sale_tiktok', <ShoppingCartOutlined />, [
    getItem(<Link to="/main">Tặng Quà Live</Link>, 'online_gifts'),
    getItem(<Link to="/main">Mua Rương</Link>, 'buy_chests'),
    getItem(<Link to="/main">Bán Xu Nhanh</Link>, 'sell_xu_quick'),
    getItem(<Link to="/main">Mua Bán Nick</Link>, 'sale_nick'),
    getItem(<Link to="/main">Dịch Vụ Tiktok</Link>, 'tiktok_service'),
  ]),

  getItem('Công Cụ Tiktok', 'tiktok_tools', <ToolOutlined />, [
    getItem(<Link to="/main">Spam Tiktok</Link>, 'spam_tiktok'),
    getItem(<Link to="/main">Nâng cấp Vip</Link>, 'upgrade_vip'),
    getItem(<Link to="/sendgift">Tặng Quà</Link>, 'give_gift'),
    getItem(<Link to="/treasurebox">Thả Rương - Túi Quà</Link>, 'treasurebox'),
    getItem(<Link to="/main">Nhặt Rương - Túi Quà</Link>, 'pick_chest_gift_bag'),
    getItem(<Link to="/main">Quét Rương</Link>, 'scan_chests'),
    getItem(<Link to="/main">Quét Túi Quà</Link>, 'scan_gift_bag'),
    getItem(<Link to="/main">Live Stream</Link>, 'live_stream'),
    getItem(<Link to="/main">Change Tiktok</Link>, 'change_tiktok'),
    getItem(<Link to="/main">Quét Theo Dõi</Link>, 'scan_follow'),
    getItem(<Link to="/main">Lịch Sử Tiktok</Link>, 'tiktok_history'),
  ]),
];

const XuMenu: React.FC = () => {
  const location = useLocation();

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isOpenFromMobile = !(isDesktopOrLaptop || isBigScreen);

  return (
    <div style={{ backgroundColor: 'white', textAlign: 'center', position: (isOpenFromMobile ? 'absolute' : 'unset'), zIndex: (isOpenFromMobile ? 1 : 0) }}>
      <div style={{ marginTop: 50 }}>
        <Link to='/'>
          <img
            alt='WinMail'
            src={toAbsoluteUrl('/media/logos/logo_198px_white.png')}
            className='logo-default h-40px'
          />
        </Link>
      </div>
      <Divider type="horizontal" />
      <div style={{ height: '90vh', overflowY: 'scroll'  }}>
        <Menu
          onClick={onClick}
          style={{ width: 256 }}
          defaultSelectedKeys={[location.pathname.replace('/', '')]}
          mode="inline"
          items={items}
        />
      </div>
    </div>
    
  );
};

export default XuMenu;
